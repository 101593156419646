import Navbar from '../landing/Navbar'
import Hero from '../landing/Hero'
import { Footer } from '../landing/Footer'
import Banner from '../layout/Banner'
import press from '../../utils/content'


export default function Press() {

  let videoContent = press.filter(x => x.type === "video");
  videoContent.sort((a, b) => b.datetime - a.datetime);
  let articleContent = press.filter(x => x.type === "article");
  articleContent.sort((a, b) => b.datetime - a.datetime);

  return (
    <>
      <Navbar/>

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                Hot off the Press!
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  We're thrilled that our work has been recognised globally by a variety of publications and we'll keep building amazing products in the hope that people will continue talking about us for all the right reasons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      <div className="bg-white">
        <main className="isolate">

          {/* Press content */}
          <div className="mx-auto max-w-7xl px-6 pt-8 sm:pt-12 pb-24 sm:pb-28 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">In the Press</h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Here's some of the coverage that we've received from around the world and across industries, feel free to peruse! 
              </p>
            </div>

            {/* Articles */}
            <h2 className="text-3xl font-bold leading-7 text-info mt-24 sm:mt-36" id="articles">Articles</h2>
            <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {articleContent.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-transparent px-8 pb-8 pt-32 sm:pt-36 lg:pt-40 hover:ring-2 hover:ring-offset-2 hover:ring-info"
                >
                  <img src={post.bgImage} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    <time className="mr-8">
                      {post.date}
                    </time>
                    {/* <div className="-ml-4 flex items-center gap-x-4">
                      <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="flex gap-x-2.5">
                        <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                        {post.author.name}
                      </div>
                    </div> */}
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a target="_blank" href={post.href} rel="noreferrer">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                </article>
              ))}
            </div>

            {/* Videos */}
            <h2 className="text-3xl font-bold leading-7 text-info mt-24 sm:mt-36" id="videos">Videos</h2>
            <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {videoContent.map((video) => (
                <iframe 
                  key={video.id} 
                  title={video.title} 
                  src={video.href}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-transparent hover:ring-2 hover:ring-offset-2 hover:ring-info w-full h-64"
                />
              ))}
            </div>

          </div>
        </main>

      </div>

      <Banner />
      <Footer />
    </>
  )
}
