import { Popover } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { Navlinks } from './Navlinks'
import { ChevronUpIcon, Bars3Icon } from '@heroicons/react/24/outline'
import { headerLinks } from '../../utils/links'
import MobileNavLink from '../layout/MobileNavLink'
import useWindowDimensions from '../../contexts/DimensionsProvider'
import { useEffect, useRef, useState } from 'react'


export default function Navbar({reverseAtTop}: {reverseAtTop?: boolean}) {

  const imgRef = useRef<HTMLImageElement | null>(null);
  const { height } = useWindowDimensions();
  const [scrollPosition, setScrollPosition] = useState(0);

  const switchTheme = scrollPosition > height - 80;
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function getNavbarButtonTheme() {
    if (reverseAtTop) return 'hoverLight';
    if (switchTheme)
      return 'hoverLight';
    if (!switchTheme)
      return 'hoverGlass';
    return "dark"
  }

  function getLogo() {
    if (reverseAtTop) return '/img/logo/detail_dark.svg';
    if (switchTheme) return '/img/logo/detail_dark.svg';
    const check = document.getElementById("smallViewportMenu");
    if (check !== null) return '/img/logo/detail_dark.svg';
    return '/img/logo/detail_light.svg';
  }

  function getNavbarTheme() {
    if (reverseAtTop) return 'bg-white';
    if (switchTheme) return 'drop-shadow bg-white';
    return 'bg-blur bg-transparent';
  }

  function getMenuButtonColour() {
    if (switchTheme) return 'dark';
    return 'white';
  }

  function switchLogo(e: any) {
    if (imgRef === null) return;
    if (imgRef.current === null) return;
    const logoSrc = imgRef.current.src;
    if (logoSrc.includes("detail_dark")) {
      if (!switchTheme) {
        imgRef.current.src = "/img/logo/detail_light.svg";
      }
    } else {
      imgRef.current.src = "/img/logo/detail_dark.svg";
    }
  }

  return (
    <header className={`sticky top-0 z-50 ${getNavbarTheme()}`}>
      <nav>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between py-8">
          <div className="relative z-10 flex items-center gap-16">

            {/* Logo button */}
            <a href="/" aria-label="Home">
              <span className="sr-only">audico</span>
              <img className="h-10 w-auto" src={getLogo()} alt="audico home logo" ref={imgRef}/>
            </a>

            {/* Nav links */}
            <div className="hidden lg:flex gap-6">
              <Navlinks links={headerLinks} colour={getNavbarButtonTheme()}/>
            </div>
          </div>

          {/* Dropdown menu for small viewports */}
          <div className="flex items-center gap-6">
            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-4 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle site navigation"
                    onClick={(e) => switchLogo(e)}
                  >
                    {({ open }) =>
                      open ? <ChevronUpIcon className="h-6 w-6 text-dark" /> : <Bars3Icon className={`h-6 w-6 text-${getMenuButtonColour()}`} />
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          id="smallViewportMenu" 
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >

                          {/* Mobile nav links */}
                          <div className="space-y-4">
                            {headerLinks.map((x, idx) => MobileNavLink({ key: idx.toString(), href: x[1], text: x[0], className: "hover:bg-slate-400/10" }))}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>

          </div>
        </div>
      </nav>
    </header>
  )
}


Navbar.defaultProps = {
  reverseAtTop: false
}