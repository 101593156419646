import Link from './Link';

interface MobileNavLinkProps {
  key: string;
  href: string;
  text: string;
  className?: string;
  icon?: any
}

export default function MobileNavLink({ key, href, text, icon, className }: MobileNavLinkProps) {
  return (
    <Link key={key} href={href} className={`block rounded-lg ${className}`}>
      <span className="flex justify-center items-center">
        {icon && <span className="mr-2">{icon}</span>}
        {text}
      </span>
    </Link>
  )
}