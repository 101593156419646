import { Tab } from '@headlessui/react'
import { BuildingOffice2Icon, BuildingOfficeIcon, HeartIcon, HomeModernIcon } from '@heroicons/react/24/solid'

const features = [
  {
    name: 'Hospitality',
    summary: 'Increase revenue and loyalty through hyper-personalised experiences',
    description: 'Build proactive patterns with your customers through personalised campaigns, self-service ordering and automated payments.',
    image: "/img/graphics/screenshots/campaigns.svg",
    icon: BuildingOffice2Icon,
    colour: "primary"
  },
  {
    name: 'Senior Living',
    summary: 'Empower residents to live independently and to stay connected',
    description: 'Give residents independence and freedom, while providing their loved ones with peace of mind and a sense of security.',
    image: "/img/graphics/screenshots/surveys.svg",
    icon: HeartIcon,
    colour: "danger"
  },
  // {
  //   name: 'Hotels',
  //   summary: 'Increase operational efficiency, streamline guest experience and improve customer satisfaction',
  //   description: 'Reduce manual workload for staff and provide delightful experiences for guests through self-service ordering and automated payments.',
  //   image: "/img/graphics/screenshots/campaigns.svg",
  //   icon: BuildingOfficeIcon,
  //   colour: "info"
  // },
  {
    name: 'At Home',
    summary: 'Be more involved in your loved ones life and play an active role.',
    description: 'Our platform provides hundreds of touch-points to play an active and important role in the day to day life of a loved one.',
    image: "/img/graphics/screenshots/home.svg",
    icon: HomeModernIcon,
    colour: "success"
  },
]

function Feature({ feature, isActive, className }: { feature: any, isActive: boolean, className: string }) {
  return (
    <div
      className={`${className} ${!isActive && 'opacity-75 hover:opacity-100'}`}
    >
      <div className={`w-9 h-9 rounded-lg`}>
        <svg aria-hidden="true" className={`h-8 w-8 mx-auto ${isActive ? `text-${feature.colour}` : 'text-slate-400'}`} fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3
        className={`mt-6 text-sm font-medium outline-none ${isActive ? `text-${feature.colour}` : 'text-slate-400'}`}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {feature.summary}
      </p>
      <p className="mt-4 text-sm text-slate-600">{feature.description}</p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl shadow-lg">
              <img
                className="w-full"
                src={feature.image}
                alt=""
                sizes="52.75rem"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="outline-none [&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-20 overflow-hidden rounded-3xl px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.name}
                  className={`px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none ${featureIndex !== selectedIndex && 'opacity-60'}`}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg">
                    <img
                      className="w-full"
                      src={feature.image}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  )
}

export default function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="py-32 sm:py-48 pb-0 sm:pb-0"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl gotham-bold">
            Cross Industry solutions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            We provide best in class solutions for multiple industries, with domain specific features for each.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </div>
    </section>
  )
}
