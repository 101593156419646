import { getIcon } from "./icons"

export const headerLinks = [
  ["About", "/about"],
  ["Proposition", "/proposition"],
  // ["Success", "/success"],
  ['Partners', '/partners'],
  ['Press', '/press'],
  ['Pricing', '/pricing'],
  ['FAQs', '/faqs'],
  ['Contact', '/contact'],
]

export const footerLinks = {
  solutions: [
    { name: 'Hospitality', href: 'https://hospitality.audico.co.uk' },
    { name: 'Hotels', href: 'https://hospitality.audico.co.uk' },
    { name: 'Healthcare', href: 'https://health.audico.co.uk' },
    { name: 'Home', href: '#!' },
  ],
  support: [
    { name: 'Pricing', href: "/pricing" },
    { name: 'FAQs', href: "/faqs" },
    { name: 'Contact', href: "/contact" },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Proposition', href: '/proposition' },
    { name: 'Partners', href: '/partners' },
    { name: 'Press', href: '/press' },
    // { name: 'Success stories', href: '/success' },
  ],
  legal: [
    { name: 'Claim', href: '#!' },
    { name: 'Privacy', href: '#!' },
    { name: 'Terms', href: '#!' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: "https://www.linkedin.com/company/audico-uk",
      icon: getIcon("linkedin", 6)
    },
    {
      name: 'Facebook',
      href: "https://www.facebook.com/weareaudico",
      icon: getIcon("facebook", 6)
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/audico_',
      icon: getIcon("instagram", 6)
    },
    {
      name: 'Twitter',
      href: "https://twitter.com/audico_",
      icon: getIcon("twitter", 6)
    },
    {
      name: 'GitHub',
      href: 'https://github.com/audico-technology',
      icon: getIcon("github", 6)
    },
  ],
}