import { Fragment, JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useState } from 'react'
import Navbar from '../landing/Navbar'
import Hero from '../landing/Hero'
import { Footer } from '../landing/Footer'
import Banner from '../layout/Banner'
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import Button from '../layout/Button'
import { BuildingOffice2Icon, HeartIcon, HomeModernIcon } from '@heroicons/react/24/solid'

interface Feature {
  name: string;
  tiers: [
    { name: "Control", value: null | boolean | string },
    { name: "Concierge", value: null | boolean | string },
    { name: "Complete", value: null | boolean | string },
  ]
}

// General - standard Alexa functionality
// ASP - proactive marketing, announcements, etc...


interface Section {
  name: string;
  features: Array<Feature>;
}

const pricing: any = [
  {
    industry: "Hospitality",
    title: "These packages and features apply if you're a property or venue within the hospitality or hotel industry.",
    tiers: [
      {
        name: 'Control',
        id: 'tier-control',
        href: '/contact',
        priceMonthly: '£12.99',
        description: 'Enables your guests to control their environment such as lighting, heating & audio.',
        mostPopular: false,
      },
      {
        name: 'Concierge',
        id: 'tier-concierge',
        href: '/contact',
        priceMonthly: '£14.99',
        description: 'A fully integrated self service concierge system with customisable features and branding.',
        mostPopular: true,
      },
      {
        name: 'Complete',
        id: 'tier-complete',
        href: '/contact',
        priceMonthly: '£20.99',
        description: 'Manage all payments, bookings and guest requests from one centralised hub.',
        mostPopular: false,
      },
    ],
    sections: [
      {
        name: 'Dashboard',
        features: [
          {
            name: 'Real time updates', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Room administration', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Device administration', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Proactive marketing campaigns', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Analytics', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Audit log', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Instant & scheduled reminders', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Video & voice calling', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Content management', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Guest orientation', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Custom integration', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Feedback channels', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          }
        ],
      },
      {
        name: 'Environmental controls',
        features: [
          {
            name: 'Lighting', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Heating', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Television', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: "Blinds", tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Air conditioning', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Advanced controls', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Concierge',
        features: [
          {
            name: 'Food ordering', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Drink ordering', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Merchandise ordering', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Bookings', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Reservations', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Local information', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Schedule updates', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Content management', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Payments',
        features: [
          {
            name: 'Gateway integration', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Instant payments', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Guest check-out', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Point of sale', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Support',
        features: [
          {
            name: 'Included level of support', tiers: [
              { name: "Control", value: "Basic" },
              { name: "Concierge", value: "Standard" },
              { name: "Complete", value: "Premium" },
            ]
          },
        ],
      },
    ],
  },
  {
    industry: "Health",
    title: "These packages and features apply if you're a property or venue within the senior living industry.",
    tiers: [
      {
        name: 'Control',
        id: 'tier-control',
        href: '/contact',
        priceMonthly: '£9.99',
        description: 'Enables your residents to control their environment such as lighting, heating & audio.',
        mostPopular: false,
      },
      {
        name: 'Concierge',
        id: 'tier-concierge',
        href: '/contact',
        priceMonthly: '£12.99',
        description: 'A fully integrated self service concierge system with customisable features and branding.',
        mostPopular: true,
      },
      {
        name: 'Complete',
        id: 'tier-complete',
        href: '/contact',
        priceMonthly: '£16.99',
        description: 'Access to exclusive content such as audiobooks & videos as well as monitoring systems for early stages of degradation.',
        mostPopular: false,
      },
    ],
    sections: [
      {
        name: 'Dashboard',
        features: [
          {
            name: 'Real time updates', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Room administration', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Device administration', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Graphical branding', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Analytics', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Audit log', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Instant & scheduled reminders', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Video & voice calling', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Content management', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Resident orientation', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Custom integration', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Feedback channels', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          }
        ],
      },
      {
        name: 'Environmental controls',
        features: [
          {
            name: 'Lighting', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Heating', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Television', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: "Blinds", tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Air conditioning', tiers: [
              { name: "Control", value: true },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Advanced controls', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Concierge',
        features: [
          {
            name: 'Food ordering', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Drink ordering', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Bookings', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Local information', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Schedule updates', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Content management', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: true },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Experience',
        features: [
          {
            name: 'Exclusive audiobooks', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Exclusive video content', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Custom broadcasts', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Immersive experiences', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Stimulatory games & activities', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
          {
            name: 'Preventative monitoring system', tiers: [
              { name: "Control", value: false },
              { name: "Concierge", value: false },
              { name: "Complete", value: true },
            ]
          },
        ],
      },
      {
        name: 'Support',
        features: [
          {
            name: 'Included level of support', tiers: [
              { name: "Control", value: "Basic" },
              { name: "Concierge", value: "Standard" },
              { name: "Complete", value: "Premium" },
            ]
          },
        ],
      },
    ],
  },
  {
    industry: "Home",
    title: "These packages and features apply if you're a person living at home.",
    tiers: [
      {
        name: 'Companion',
        id: 'tier-companion',
        href: '#',
        priceMonthly: '£14.99',
        description: 'An all-in-one package for a lonely or isolated individual at home.',
        mostPopular: true,
      },
      {
        name: 'Advanced',
        id: 'tier-advanced',
        href: '#',
        priceMonthly: '£19.99',
        description: 'Enhanced internet connectivity & support',
        mostPopular: false,
      }
    ],
    sections: [
      {
        name: 'Smart device',
        features: [
          {
            name: 'Fully configured smart device', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Fully anonymised requests', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Power cables', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Voice activation', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Touch screen', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
        ],
      },
      {
        name: 'Internet connectivity',
        features: [
          {
            name: 'Fully configured internet router', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Out-of-the box usage', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'High speed bandwidth', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          }
        ],
      },
      {
        name: 'Features',
        features: [
          {
            name: 'Discover interest groups', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Explore & enjoy content', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Find likeminded friends', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Video call with family & friends', tiers: [
              { name: "Companion", value: true },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Instant message with family & friends', tiers: [
              { name: "Companion", value: false },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Guardian access', tiers: [
              { name: "Companion", value: false },
              { name: "Advanced", value: true },
            ]
          },
          {
            name: 'Risk analysis & recommendations', tiers: [
              { name: "Companion", value: false },
              { name: "Advanced", value: true },
            ]
          }
        ],
      },
      {
        name: 'Support',
        features: [
          {
            name: 'Included level of support', tiers: [
              { name: "Companion", value: "Basic" },
              { name: "Advanced", value: "Premium" },
            ]
          },
        ],
      },
    ],
  }
]

const support: any = [
  {
    tiers: [
      {
        name: 'Basic',
        id: 'tier-basic-support',
        href: '/contact',
        priceMonthly: '0',
        description: '',
        mostPopular: false,
      },
      {
        name: 'Standard',
        id: 'tier-standard-support',
        href: '/contact',
        priceMonthly: '£5.99',
        description: '',
        mostPopular: true,
      },
      {
        name: 'Premium',
        id: 'tier-premium-support',
        href: '/contact',
        priceMonthly: '£11.99',
        description: '',
        mostPopular: false,
      },
    ],
    sections: [
      {
        name: 'Contact',
        features: [
          {
            name: 'Create support tickets', tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ]
          },
          {
            name: '24/7 email support', tiers: [
              { name: "Basic", value: true },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ]
          },
          {
            name: '24/7 phone support', tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ]
          },
        ],
      },
      {
        name: 'Expertise',
        features: [
          {
            name: 'Project roadmap', tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: true },
              { name: "Premium", value: true },
            ]
          },
          {
            name: 'Dedicated project manager', tiers: [
              { name: "Basic", value: false },
              { name: "Standard", value: false },
              { name: "Premium", value: true },
            ]
          },
          {
            name: 'Custom development', tiers: [
              { name: "Basic", value: "Charged per requirements" },
              { name: "Standard", value: "Charged per requirements" },
              { name: "Premium", value: "Discounted rate" },
            ]
          },
        ],
      },
    ],
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function buildFeature(feature: Feature, name: string) {
  const tiers = feature.tiers;
  const filteredTiers = tiers.filter(tier => tier.name === name);
  if (filteredTiers.length === 0) return <CheckIcon className="h-6 w-5 flex-none text-info" aria-hidden="true" />;
  const tier = filteredTiers[0];
  if (typeof tier.value === 'string') return <span className="text-sm leading-6 text-gray-500">({tier.value})</span>;
  if (tier.value === true) {
    return <CheckIcon className="h-6 w-5 flex-none text-info" aria-hidden="true" />
  } else {
    return <MinusIcon className="h-6 w-5 flex-none text-gray-400" aria-hidden="true" />
  }
}

function buildFeatureLarge(feature: Feature, name: string) {
  const tiers = feature.tiers;
  const filteredTiers = tiers.filter(tier => tier.name === name);
  if (filteredTiers.length === 0) return null;
  const tier = filteredTiers[0];
  if (tier.value === null) return null;
  if (typeof tier.value === 'string') return <div className="text-center text-sm leading-6 text-gray-500">{tier.value}</div>
  if (tier.value === true) {
    return (
      <>
        <CheckIcon className="mx-auto h-5 w-5 text-info" aria-hidden="true" />
        <span className="sr-only">{`Included in ${tier.name}`}</span>
      </>
    )
  } else {
    return (
      <>
        <MinusIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
        <span className="sr-only">{`Not included in ${tier.name}`}</span>
      </>
    )
  }
}

export default function Pricing() {

  const [selectedIndustry, setSelectedIndustry] = useState('Hospitality');

  function handleIndustryClick(e: any, args: string[]) {
    e.preventDefault();
    const [industry] = args;
    setSelectedIndustry(industry);
    const selectedElement = document.getElementById("industry-pricing-sections");
    if (selectedElement != null) selectedElement.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <Navbar />

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                One size never fits all.
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  We have plans for teams and businesses of all sizes. Use the information below as a guideline, when you engage with us we'll prepare something custom for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      <div className="bg-white">
        <main className="isolate">

          {/* Pricing */}
          <div className="mx-auto max-w-7xl px-6 pt-2 sm:pt-4 pb-16 sm:pb-20">
            <div className="mx-auto max-w-4xl text-center mt-0 lg:mt-20">
              <h2 className="text-4xl font-bold leading-7 text-info">Pricing</h2>
              <p className="mt-4 text-gray-600">Choose an industry to see specific packages and prices</p>
            </div>

            {/* Choose an industry */}
            <div className="max-w-lg mx-auto grid grid-cols-1 sm:grid-cols-3 gap-y-2 sm:gap-y-0 gap-x-6 mt-10 mb-20">
              <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Hospitality" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Hospitality"]}>
                <BuildingOffice2Icon className="h-6 w-6 text-primary mx-auto mb-2" aria-hidden="true" />
                Hospitality
              </Button>
              <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Health" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Health"]}>
                <HeartIcon className="h-6 w-6 text-danger mx-auto mb-2" aria-hidden="true" />
                Health
              </Button>
              <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Home" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Home"]}>
                <HomeModernIcon className="h-6 w-6 text-success mx-auto mb-2" aria-hidden="true" />
                Home
              </Button>
            </div>


            {/* Pricing bands */}
            {pricing.filter((x: any) => x.industry === selectedIndustry).map((industry: any, idx: number) => (
              <div className="pt-20 border-t-2" key={idx} id="industry-pricing-sections">

                {/* xs to lg */}
                <div className="mx-auto mt-8 max-w-md space-y-8 sm:mt-10 lg:hidden">
                  {industry.tiers.map((tier: any) => (
                    <section
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                        'p-8'
                      )}
                    >
                      <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                        {tier.name}
                      </h3>
                      <span className="mt-2 text-xs">Starting from</span>
                      <p className="flex items-baseline gap-x-1 text-gray-900">
                        <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                        <span className="text-sm font-semibold">/month</span>
                      </p>
                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-info text-white hover:bg-fuchsia-800'
                            : 'text-info ring-1 ring-inset ring-info hover:ring-info',
                          'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                        )}
                        target="_blank" rel="noreferrer"
                      >
                        Get in touch
                      </a>
                      <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                        {industry.sections.map((section: Section) => (
                          <li key={section.name}>
                            <ul className="space-y-4">
                              {section.features.map((feature: Feature) => (
                                <li key={feature.name} className="flex gap-x-3">
                                  {feature.name}{' '}
                                  <span className="ml-auto">
                                    {buildFeature(feature, tier.name)}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </section>
                  ))}
                </div>

                {/* lg+ */}
                <div className="isolate mt-12 hidden lg:block">
                  <div className="relative -mx-8">
                    {industry.tiers.some((tier: { mostPopular: any }) => tier.mostPopular) ? (
                      <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                        <div
                          className={`flex w-1/${industry.industry === "Home" ? 3 : 4} px-4`}
                          aria-hidden="true"
                          style={{ marginLeft: industry.industry === "Home" ? `${(industry.tiers.findIndex((tier: { mostPopular: any }) => tier.mostPopular) + 1) * 33}%` : `${(industry.tiers.findIndex((tier: { mostPopular: any }) => tier.mostPopular) + 1) * 25}%` }}
                        >
                          <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                        </div>
                      </div>
                    ) : null}
                    <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                      <caption className="sr-only">Pricing plan comparison</caption>
                      <colgroup>
                        {industry.industry === "Home" ? (
                          <>
                            <col className="w-1/3" />
                            <col className="w-1/3" />
                            <col className="w-1/3" />
                          </>
                        ) : (
                          <>
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                          </>
                        )}

                      </colgroup>
                      <thead>
                        <tr>
                          <td />
                          {industry.tiers.map((tier: { id: Key | null | undefined; name: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => (
                            <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                              <div className="text-sm font-semibold leading-7 text-gray-900">{tier.name}</div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <span className="sr-only">Price</span>
                          </th>
                          {industry.tiers.map((tier: { id: Key | null | undefined; priceMonthly: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; href: string | undefined; mostPopular: any }) => (
                            <td key={tier.id} className="px-6 pt-2 xl:px-8">
                              <span className="text-xs">Starting from</span>
                              <div className="flex items-baseline gap-x-1 text-gray-900">
                                <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                                <span className="text-sm font-semibold leading-6">/month</span>
                              </div>
                              <a
                                href={tier.href}
                                className={classNames(
                                  tier.mostPopular
                                    ? 'bg-info text-white hover:bg-fuchsia-800'
                                    : 'text-info ring-1 ring-inset ring-info hover:ring-info',
                                  'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                                )}
                                target="_blank" rel="noreferrer"
                              >
                                Get in touch
                              </a>
                            </td>
                          ))}
                        </tr>
                        {industry.sections.map((section: any, sectionIdx: number) => (
                          <Fragment key={section.name}>
                            <tr>
                              <th
                                scope="colgroup"
                                colSpan={4}
                                className={classNames(
                                  sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                  'pb-4 text-sm font-semibold leading-6 text-gray-900'
                                )}
                              >
                                {section.name}
                                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                              </th>
                            </tr>
                            {section.features.map((feature: Feature) => (
                              <tr key={feature.name}>
                                <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                                  {feature.name}
                                  <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                                </th>
                                {industry.tiers.map((tier: { id: Key | null | undefined; name: string }) => (
                                  <td key={tier.id} className="px-6 py-4 xl:px-8">
                                    {buildFeatureLarge(feature, tier.name)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            ))}
          </div>

          {/* Support */}
          <div className="mx-auto max-w-7xl px-6 pt-16 sm:pt-20 pb-28 sm:pb-32">
            <div className="mx-auto max-w-4xl text-center mt-0 lg:mt-20">
              <h2 className="text-4xl font-bold leading-7 text-info">Support</h2>
              <p className="mt-4 text-gray-600">If you need ongoing support, we can help you whatever your needs are</p>
            </div>

            {/* Support */}
            {support.map((spt: any, idx: number) => (
              <div key={idx} className="pt-20">

                {/* xs to lg */}
                <div className="mx-auto mt-8 max-w-md space-y-8 sm:mt-10 lg:hidden">
                  {spt.tiers.map((tier: any) => (
                    <section
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                        'p-8'
                      )}
                    >
                      <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                        {tier.name}
                      </h3>
                      <span className="text-xs mt-2">Starting from</span>
                      <p className="flex items-baseline gap-x-1 text-gray-900">
                        <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                        <span className="text-sm font-semibold">/month</span>
                      </p>
                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-info text-white hover:bg-fuchsia-800'
                            : 'text-info ring-1 ring-inset ring-info hover:ring-info',
                          'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                        )}
                        target="_blank" rel="noreferrer"
                      >
                        Get in touch
                      </a>
                      <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                        {spt.sections.map((section: Section) => (
                          <li key={section.name}>
                            <ul className="space-y-4">
                              {section.features.map((feature: Feature) => (
                                <li key={feature.name} className="flex gap-x-3">
                                  {feature.name}{' '}
                                  <span className="ml-auto">
                                    {buildFeature(feature, tier.name)}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </section>
                  ))}
                </div>

                {/* lg+ */}
                <div className="isolate mt-12 hidden lg:block">
                  <div className="relative -mx-8">
                    {spt.tiers.some((tier: { mostPopular: any }) => tier.mostPopular) ? (
                      <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                        <div
                          className={`flex w-1/4 px-4`}
                          aria-hidden="true"
                          style={{ marginLeft: `${(spt.tiers.findIndex((tier: { mostPopular: any }) => tier.mostPopular) + 1) * 25}%` }}
                        >
                          <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                        </div>
                      </div>
                    ) : null}
                    <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                      <caption className="sr-only">Pricing plan comparison</caption>
                      <colgroup>
                        <col className="w-1/4" />
                        <col className="w-1/4" />
                        <col className="w-1/4" />
                        <col className="w-1/4" />
                      </colgroup>
                      <thead>
                        <tr>
                          <td />
                          {spt.tiers.map((tier: { id: Key | null | undefined; name: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => (
                            <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                              <div className="text-sm font-semibold leading-7 text-gray-900">{tier.name}</div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <span className="sr-only">Price</span>
                          </th>
                          {spt.tiers.map((tier: { id: Key | null | undefined; priceMonthly: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; href: string | undefined; mostPopular: any }) => (
                            <td key={tier.id} className="px-6 pt-2 xl:px-8">
                              <span className="text-xs">Starting from</span>
                              <div className="flex items-baseline gap-x-1 text-gray-900">
                                <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                                <span className="text-sm font-semibold leading-6">/month</span>
                              </div>
                              <a
                                href={tier.href}
                                className={classNames(
                                  tier.mostPopular
                                    ? 'bg-info text-white hover:bg-fuchsia-800'
                                    : 'text-info ring-1 ring-inset ring-info hover:ring-info',
                                  'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-info'
                                )}
                                target="_blank" rel="noreferrer"
                              >
                                Get in touch
                              </a>
                            </td>
                          ))}
                        </tr>
                        {spt.sections.map((section: any, sectionIdx: number) => (
                          <Fragment key={section.name}>
                            <tr>
                              <th
                                scope="colgroup"
                                colSpan={4}
                                className={classNames(
                                  sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                  'pb-4 text-sm font-semibold leading-6 text-gray-900'
                                )}
                              >
                                {section.name}
                                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                              </th>
                            </tr>
                            {section.features.map((feature: Feature) => (
                              <tr key={feature.name}>
                                <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                                  {feature.name}
                                  <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                                </th>
                                {spt.tiers.map((tier: { id: Key | null | undefined; name: string }) => (
                                  <td key={tier.id} className="px-6 py-4 xl:px-8">
                                    {buildFeatureLarge(feature, tier.name)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            ))}
          </div>
        </main>
      </div>

      <Banner />
      <Footer />
    </>
  )
}
