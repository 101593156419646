import getButtonStyle from "../../utils/styles";

export default function NotFound() {
  return (
    <main className="grid h-screen place-items-center bg-gradient-to-r from-info to-primary background-animate px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-white">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-slate-200">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className={`${getButtonStyle("solid", "dark")}`}
          >
            Go back home
          </a>
          <a href="/contact" className="text-sm font-semibold text-white">
            Contact us <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}
