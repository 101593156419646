import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import Banner from '../layout/Banner'
import { Footer } from '../landing/Footer'
import Hero from '../landing/Hero'
import Navbar from '../landing/Navbar'

const faqs = [
  {
    question: "How does audico work?",
    answer:
      "Our platform is made up of a series of interoperable, intelligent components. First of all, we embed our AI powered voice technology into voice & touch enabled devices. When an end user makes a request through this device, it is sent to audico for processing where our intelligent system creates a response quickly and accurately. In the meantime, the relevant property or venue is notified through a dashboard or notification. The end user can then interact with the response and continue with more requests if needed.",
  },
  {
    question: "How is AI used in audico?",
    answer:
      "We are strong believers that although AI is prevalent across society and the usage is only likely to increase in the future, it should be used carefully, mindfully and responsibly. We use AI to understand natural language, generate content and to enhance our overall service. However, we NEVER use personal data for any of this.",
  },
  {
    question: "Does audico listen to me all the time?",
    answer:
      "Absolutely not. With certain devices that we work with, they may need to be \"woken\" to become active before they are able to process your speech. When a device is active, then and only then will the device listen for your speech.",
  },
  {
    question: "Does audico record my voice?",
    answer:
      "Never. Data privacy is at the core of what we do. We do not record or store any voice recordings at all. When you make a voice request, it is completely anonymous.",
  },
  {
    question: "Is my data safe when using audico?",
    answer:
      "Most definitely. We are constantly enhancing, monitoring and developing our technology not only to provide a better product but to ensure the best cybersecurity.",
  },
  {
    question: "Do I have to use my voice to use audico?",
    answer:
      "No! Accessibility is really important to us, with anywhere we deploy you have the option to interact through voice or touch! Furthermore, we can run on a wide variety of devices and you can bring your own devices if you wish.",
  },
]

export default function FAQs() {
  return (
    <>
      <Navbar/>

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                Curious to know more?
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  Take a look at our FAQs below and if you can't find the answer you're looking for or have a specific question to field, our team is waiting to hear from you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 pt-8 sm:pt-16 pb-28 sm:pb-32">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-info">Frequently asked questions</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <Banner />
      <Footer />
    </>
  )
}
