import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";

// Redux
import { store } from "./store";
import Landing from "./components/landing/Landing";
import NotFound from "./components/routing/NotFound";
import About from "./components/about/About";
import Partners from "./components/partners/Partners";
import Press from "./components/press/Press";
import Pricing from "./components/pricing/Pricing";
import Contact from "./components/contact/Contact";
import FAQs from "./components/faq/FAQs";
import Value from "./components/value/Value";


function App() {
   return (
    <Provider store={store}>
      <Router>
        <Routes>

          {/* Landing */}
          <Route path="/" element={<Landing />} />

          {/* About */}
          <Route path="/about" element={<About />} />

          {/* Value */}
          <Route path="/proposition" element={<Value />} />

          {/* Partners */}
          <Route path="/partners" element={<Partners />} />

          {/* Press */}
          <Route path="/press" element={<Press />} />

          {/* Pricing */}
          <Route path="/pricing" element={<Pricing />} />

          {/* Contact */}
          <Route path="/contact" element={<Contact header="Get in touch!" subtext="Tell us as much information about your query as possible, so that we can respond quickly and accurately." />} />

           {/* FAQs */}
           <Route path="/faqs" element={<FAQs />} />
          
          {/* 404 */}
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;