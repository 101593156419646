import { useState } from 'react'
// import { Switch } from '@headlessui/react'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import sendTemplatedEmailSes from '../../utils/email'
import { useDispatch } from 'react-redux'
import { addAlert } from '../../slices/alert'
import generateAlertText from '../../utils/generateAlertText'
import Banner from '../layout/Banner'

// function classNames(...classes: string[]) {
//   return classes.filter(Boolean).join(' ')
// }

export default function Contact({header, subtext}: {header: string; subtext: string}) {
  const dispatch = useDispatch();
  // const [agreed, setAgreed] = useState(false)

  const [contactState, setContactState] = useState({
    email: "",
    subject: "General inquiry",
    body: "",
  });

  const onChange = (e: any) =>
  setContactState({
      ...contactState,
      [e.target.name]: e.target.value,
    });

  async function sendContactInquiry(e: any) {
    e.preventDefault();
    const { email, subject, body } = contactState;
    const success = sendTemplatedEmailSes(email, subject, body);
    if (success === false) {
      dispatch(addAlert(generateAlertText("contact_inquiry_fail"), "danger"));
      return;
    }
    dispatch(addAlert(generateAlertText("contact_inquiry_success"), "success"));
    setContactState({
      email: "",
      subject: "General inquiry",
      body: "",
    });
  }

  return (
    <>
      <div className="w-full h-screen bg-gradient-to-r from-info to-primary background-animate">
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl p-8 w-full max-w-md space-y-8 drop-shadow text-center">
            <div>
              <a href="/">
                <img
                  className="mx-auto h-12 w-auto rounded-lg"
                  src="/img/logo/icon_gradient.svg"
                  alt="audico"
                />
              </a>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-flint">
                {header}
              </h2>
              <p className="text-slate-500 text-sm mt-3">{subtext}</p>
            </div>
            <form
              className="mt-8 space-y-6"
              onSubmit={(e) => sendContactInquiry(e)}
            >
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-flint placeholder-slate-500 focus:z-10 focus:border-info focus:outline-none focus:ring-info sm:text-sm"
                    placeholder="Email address"
                    value={contactState.email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900 sr-only">
                    Subject
                  </label>
                  <select
                    id="subject"
                    name="subject"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-info sm:text-sm sm:leading-6"
                    defaultValue="General inquiry"
                    onChange={(e) => onChange(e)}
                  >
                    <option value="General inquiry">General inquiry</option>
                    <option value="Schedule demo">I'd like to schedule a demo</option>
                    <option value="Purchase interest">I'm interested in purchasing</option>
                    <option value="Partnership interest">I'm interested in becoming a partner</option>
                    <option value="Technical support">I need some technical support</option>
                    <option value="Other">Something else...</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="body"
                      id="message"
                      placeholder="Enter your message here..."
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 ring-1 ring-inset ring-gray-300 placeholder-slate-500 focus:ring-2 focus:ring-inset focus:ring-info sm:text-sm sm:leading-6"
                      value={contactState.body}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>

              {/* <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={agreed}
                    onChange={setAgreed}
                    className={classNames(
                      agreed ? 'bg-indigo-600' : 'bg-gray-200',
                      'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    )}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        agreed ? 'translate-x-3.5' : 'translate-x-0',
                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
                <Switch.Label className="text-sm leading-6 text-gray-600">
                  By selecting this, you agree to our{' '}
                  <a href="#!" className="font-semibold text-indigo-600">
                    privacy&nbsp;policy
                  </a>
                  .
                </Switch.Label>
              </Switch.Group> */}

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-full border border-transparent bg-info hover:bg-fuchsia-800 py-2 px-4 text-sm font-medium text-white hover:bg-info-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-info focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <PaperAirplaneIcon
                      className="h-5 w-5 fill-info-light group-hover:fill-white"
                      aria-hidden="true"
                    />
                  </span>
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Banner/>
    </>
  )
}
