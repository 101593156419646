import { BuildingOffice2Icon, HeartIcon, HomeModernIcon } from "@heroicons/react/24/solid"

const testimonials = [
  [
    {
      content: "I would not like to lose it, because it's such a help to me in the night time when you're by yourself.",
      author: {
        name: 'John',
        role: 'Resident @ Canford Care',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
    {
      content:
        "We've seen revenues on race days for participating boxes more than double since we started using audico. It has been a game-changer.",
      author: {
        name: 'Paul Paisis-Oakwell',
        role: 'Head of F&B @ Ascot Racecourse',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Hospitality',
    },
    {
      content: "What we are doing is not so much assisting people, we are assisting their independance and audico dovetails into that really well.",
      author: {
        name: 'Colin',
        role: 'Manager @ The Royal Air Forces Association',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
    {
      content: "The technology is amazing. For our residents it's empowering, it's giving them that control back and I can really see the future moving forward especially in nursing homes for our residents.",
      author: {
        name: 'Claire Roberts',
        role: 'General Manager @ Morris Care',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
  ],
  [
    {
      content: "I find now that we're getting a lot less calls on the bell for simple things, so it means we can spend more quality time with them brightening their day. If you can enhance someone's life and promote independence it's worth it. Just look at their self esteem now compared to before. There is no comparison.",
      author: {
        name: 'Mandy, Theresa & Sam',
        role: 'Carers @ Canford Care',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
    {
      content: "This experience is the future, it's so easy that I wish I could browse and order everything at a venue like this. I simply said what I wanted, completed payment and my merchandise was delivered within a few minutes.",
      author: {
        name: 'Francois',
        role: 'Guest @ Paris La Defense Arena',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Hospitality',
    },
    {
      content: "It really helps with communication within the house. It's easier for the tenant, they can access it straight away and they know it's logged. And it's easier for me because it keeps a record of issues that need addressing.",
      author: {
        name: 'David',
        role: 'Manager @ Royal Air Forces Association',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
    {
      content: "It's definitely helping the residents to be more independent and that's a massive thing in a care home. We like to promote as much independence as we can and for them it makes a massive difference too.",
      author: {
        name: 'Molly',
        role: 'Care Supervisor @ Isle Court',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Health',
    },
  ],
  [
    {
      content: 'From a business perspective this has the potential to reduce costs whilst ensuring that the resident experience remains at the heart of life in our Homes.',
      author: {
        name: 'Lucy Holl',
        role: "Chief Executive @ Morris Care",
        image: "/img/logo/icon_black.svg",
      },
      industry: "Health",
    },
    {
      content: "Virtual assistant technology is the future of hospitality, and we're thrilled to be powering the payments behind Audico. This is all about improving the customer experience, and a large part of this is seamless payments. We're pleased to be partnering with The Digital Line to make this happen.",
      author: {
        name: 'Hemlata Narasimhan',
        role: 'European President @ Elavon Merchant Services',
        image: "/img/logo/icon_black.svg",
      },
      industry: 'Hospitality',
    },
    {
      content: "Once we've rolled it out through our accommodation, how can we use this in a wider context to support our community that are still living in their own homes.",
      author: {
        name: 'Nick Bunting',
        role: "CEO @ Royal Air Forces Association",
        image: "/img/logo/icon_black.svg",
      },
      industry: "Home",
    },
    {
      content: "I can't get up from the chair or out the bed without help. Having audico do all these jobs for me with just my voice has been a great help.",
      author: {
        name: 'Edna',
        role: "Resident @ Canford Care",
        image: "/img/logo/icon_black.svg",
      },
      industry: "Health",
    },
  ],
]

function QuoteIcon({className}: {className: string}) {
  return (
    <svg aria-hidden="true" width={105} height={78} className={className}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

export default function Testimonials() {
  return (
    <section
      id="reviews"
      aria-label="What our customers are saying"
      className="relative bg-white py-32 sm:py-48"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl gotham-bold">
            Don't just take our word for it
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            We pride ourselves on our customer service and the quality of our products. See what our customers and end users have to say about us.
          </p>
        </div>
        <ul
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-slate-100 drop-shadow p-6">
                      <QuoteIcon className="absolute left-6 top-6 fill-slate-200" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-200 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden">
                          {testimonial.industry === "Hospitality" ? (
                            <BuildingOffice2Icon className="h-5 w-5 text-primary object-cover" />
                          ) : testimonial.industry === "Health" ? (
                            <HeartIcon className="h-5 w-5 text-danger object-cover" />
                          ) : <HomeModernIcon className="h-5 w-5 text-success object-cover" />}
                          {/* <img
                            className="h-14 w-14 object-cover"
                            src={testimonial.author.image}
                            alt=""
                            width={56}
                            height={56}
                          /> */}
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="mt-16 flex justify-center">
        <a href="#!" className="bg-info px-8 py-3 leading-6 text-white drop-shadow rounded-full text-xl font-bold hover:bg-fuchsia-800">
          Read our customer success stories here →
        </a>
      </div> */}
    </section>
  )
}
