const stats = [
  { id: 1, name: 'Requests handled', value: '> 30,000' },
  { id: 2, name: 'Revenue increase', value: '> 200%' },
  { id: 3, name: 'Reduction in service response time', value: '- 75%' },
  { id: 4, name: 'End user satisfaction', value: '+ 50%' },
]

export default function Stats() {
  return (
    <div className="relative isolate overflow-hidden py-32 sm:py-48 pt-24 sm:pt-36 bg-white">
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
          <h2 className="text-base font-semibold leading-8 text-primary">Our track record</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-dark sm:text-4xl">
            Trusted by thousands of users across Europe
          </p>
          <p className="mt-6 text-lg leading-8 text-slate-800">
            Thousands of end users have interacted with our systems across multiple industries and geographic locations. We currently operate across Europe, but plan to expand further soon.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-black sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.id} className="flex flex-col gap-y-3 border-l border-slate-300 pl-6">
              <dt className="text-sm leading-6">{stat.name}</dt>
              <dd className="order-first text-3xl gotham-bold tracking-tight text-info">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
