import { useState } from "react";
import Navbar from '../landing/Navbar'
import Hero from '../landing/Hero'
import Banner from '../layout/Banner'
import { Footer } from '../landing/Footer'
import { getSolidIcon } from '../../utils/icons'
import Button from "../layout/Button";
import { BuildingOffice2Icon, HeartIcon, HomeModernIcon } from "@heroicons/react/24/solid";

const hospitalityData = [
  {
    header: "Boost revenue",
    subheader: "Increase sales, repeat customers & loyalty",
    image: "/img/graphics/screenshots/campaigns.svg",
    firstText: "Our platform gives you the power to proactively engage with your customers in a personalised way to keep them coming back.",
    secondText: "In a non-intrusive way, you can subtly encourage customers to purchase promoted products, redeem offers, sign up to newsletters, make bookings and much more.",
    bullets: [
      {
        icon: getSolidIcon("credit-card", "info", 5, "mt-1 flex-none"),
        bold: "Instant sales. ",
        text: "Customers can make purchases and complete payments using their voice or a few simple touch commands. Pay-by-Voice is here."
      },
      {
        icon: getSolidIcon("gift", "info", 5, "mt-1 flex-none"),
        bold: "Personalised marketing. ",
        text: "Create hyper-personalised or segmented visual campaigns to encourage interaction, promote temporal deals or provide special one time offers."
      },
      {
        icon: getSolidIcon("heart", "info", 5, "mt-1 flex-none"),
        bold: "Die-hard loyalty. ",
        text: "Our one-of-a-kind digital loyalty system has customers coming back whilst allowing them to take their profile anywhere."
      }
    ],
    thirdText: "Our platform puts you in control. Take the guess work out of knowing what your customers want and when they want it. We provide granular information on customer behaviour and preferences so you can make informed decisions.",
    challenge: {
      title: "Need some ideas?",
      text: "No problem. We have a team of experts that can help craft the end-user experience customised for your needs. We also have a library of templates to use that can get you started instantly!"
    }
  },
  {
    header: "Optimise operations",
    subheader: "Improve operational effiency",
    image: "/img/graphics/screenshots/analytics.svg",
    firstText: "We believe in making best use of what you have available. Our platform provides a cost effective way to optimise your business and workforce without having to splash out.",
    secondText: "Our system is designed to work in harmony with staff members and to give them superpowers. It takes a matter of minutes to become an expert and is so intuitive that it can be used by anyone.",
    bullets: [
      {
        icon: getSolidIcon("rocket", "info", 5, "mt-1 flex-none"),
        bold: "Increase productivity. ",
        text: "Satisfying customers can be extremely manual. Allow us to automate and accelerate the overall customer journey from ordering, to fulfillment, to payment."
      },
      {
        icon: getSolidIcon("chart-bar", "info", 5, "mt-1 flex-none"),
        bold: "Be proactive. ",
        text: "Rather than always being on the back foot, use proactive prompts & features and know exactly what someone wants when they make a request."
      },
      {
        icon: getSolidIcon("light", "info", 5, "mt-1 flex-none"),
        bold: "Real time insights. ",
        text: "Stay informed if you're on the go or at your computer. Access insights and recommendations in real time powered by AI to help you run smarter."
      }
    ],
    thirdText: "Our platform is proven to reduce journey time for staff members, increase productivity and provide insights to promote optimised operations.",
    challenge: {
      title: "Not technical? No problem",
      text: "Our platform was built from the ground up with intuition in mind. Our UI is clean, non-cluttered and everything has human-readable explanations, even our AI!"
    }
  },
  {
    header: "Reduce costs",
    subheader: "Save significant money & time",
    image: "/img/graphics/screenshots/log.svg",
    firstText: "Why pay over the odds and be locked in to a lifetime commitment? Our platform is cost-effective and our hardware doesn't need to be replaced every year.",
    secondText: "Our flexible pricing means that you pay for what you use. Furthermore, the more you use the lower the cost.",
    bullets: [
      {
        icon: getSolidIcon("stack", "info", 5, "mt-1 flex-none"),
        bold: "Consolidate hardware. ",
        text: "Why buy 5 to 10 different systems to manage individual things when ours can cover it all?"
      },
      {
        icon: getSolidIcon("receipt-pct", "info", 5, "mt-1 flex-none"),
        bold: "Reduce cost. ",
        text: "Not only is our system multi-purpose, but it is cost-effective too with a one-time fee for hardware and flexible monthly payments for the software."
      },
      {
        icon: getSolidIcon("chart-presentation-line", "info", 5, "mt-1 flex-none"),
        bold: "Long term value. ",
        text: "Our software receives fortnightly updates so it's always improving! Our hardware is proven to last for years, even the very first release still works today!"
      }
    ],
    thirdText: "Whether it's our hardware, software or services we provide cost-effective solutions that can be tailored to your needs.",
    challenge: {
      title: "Not ready yet? Take a look first",
      text: "We're so confident of our platform and we'd love to show it to you. Get in touch to organise a demo and to discuss a pilot."
    }
  },
]

const healthData = [
  {
    header: "Boost independence",
    subheader: "Give control back to your residents",
    image: "/img/graphics/screenshots/rooms.svg",
    firstText: "As we grow older the simple things become more difficult. Giving control back to residents in any way possible is a sure fire way to boost their esteem.",
    secondText: "It may seem small, but being able to control the lights, log a maintenance issue, remind themselves of their schedule or take their medication on time can make a huge difference to someone's day.",
    bullets: [
      {
        icon: getSolidIcon("credit-card", "info", 5, "mt-1 flex-none"),
        bold: "Improved self esteem. ",
        text: "Empowering residents to do things independently makes them feel on top of the world."
      },
      {
        icon: getSolidIcon("gift", "info", 5, "mt-1 flex-none"),
        bold: "Happier, healthier life. ",
        text: "Our ambient technology is there to assist and enhance the lives of residents, not intrude."
      },
      {
        icon: getSolidIcon("heart", "info", 5, "mt-1 flex-none"),
        bold: "Empowering individuals. ",
        text: "We find that once they start they don't want to stop using it and are incredibly curious explore the possibilities."
      }
    ],
    thirdText: "We recognise that managing in a care environment is unbelievably tough. Let our easy-to-use platform take some of the stress away from staff whilst helping residents to life happier, healthier lives.",
    challenge: {
      title: "Not sure where to start?",
      text: "Don't worry, We have a team of experts that can offer suggestions on how to engage with residents through our technology and we have a library of templates to use that can get you started instantly!"
    }
  },
  {
    header: "Optimise operations",
    subheader: "Improve operational effiency",
    image: "/img/graphics/screenshots/analytics.svg",
    firstText: "We believe in making best use of what you have available. Our platform provides a cost effective way to optimise your workforce without having to splash out.",
    secondText: "Our system is designed to work in harmony with staff members and carers. It takes a matter of minutes to become an expert and is so intuitive that it can be used by anyone.",
    bullets: [
      {
        icon: getSolidIcon("rocket", "info", 5, "mt-1 flex-none"),
        bold: "Increase productivity. ",
        text: "Fulfilling requests around a property can be extremely time-consuming, especially if you don't know in advance what they want. Our platform gives your foresight and allows a place to manage everything."
      },
      {
        icon: getSolidIcon("chart-bar", "info", 5, "mt-1 flex-none"),
        bold: "Be proactive. ",
        text: "Rather than waiting until something needs to be done, use proactive prompts & features and know give an extra touch of personalisation to your service."
      },
      {
        icon: getSolidIcon("light", "info", 5, "mt-1 flex-none"),
        bold: "Real time insights. ",
        text: "Stay informed if you're on the go or at your computer. Access insights and recommendations in real time powered by AI to help you run smarter."
      }
    ],
    thirdText: "Our platform is proven to reduce journeys for staff members, increase productivity and provide insights to promote optimised operations.",
    challenge: {
      title: "Not technical? No problem",
      text: "Our platform was built from the ground up with intuition in mind. Our UI is clean, non-cluttered and everything has human-readable explanations, even our AI!"
    }
  },
  {
    header: "Reduce costs",
    subheader: "Save significant money & time",
    image: "/img/graphics/screenshots/log.svg",
    firstText: "Why pay over the odds and be locked in to a lifetime commitment? Our platform is cost-effective and our hardware doesn't need to be replaced every year.",
    secondText: "Our flexible pricing means that you pay for what you use. Furthermore, the more you use the lower the cost.",
    bullets: [
      {
        icon: getSolidIcon("stack", "info", 5, "mt-1 flex-none"),
        bold: "Consolidate hardware. ",
        text: "Why buy 5 to 10 different systems to manage individual things when ours can cover it all?"
      },
      {
        icon: getSolidIcon("receipt-pct", "info", 5, "mt-1 flex-none"),
        bold: "Reduce cost. ",
        text: "Not only is our system multi-purpose, but it is cost-effective too with a one-time fee for hardware and flexible monthly payments for the software."
      },
      {
        icon: getSolidIcon("chart-presentation-line", "info", 5, "mt-1 flex-none"),
        bold: "Long term value. ",
        text: "Our software receives fortnightly updates so it's always improving! Our hardware is proven to last for years, even the very first release still works today!"
      }
    ],
    thirdText: "Whether it's our hardware, software or services we provide cost-effective solutions that can be tailored to your needs.",
    challenge: {
      title: "Not ready yet? Take a look first",
      text: "We're so confident of our platform and we'd love to show it to you. Get in touch to organise a demo and to discuss a pilot."
    }
  },
]

const homeData = [
  {
    header: "Stay connected",
    subheader: "Get online with family & friends",
    image: "/img/graphics/screenshots/chat.svg",
    firstText: "For isolated elderly people at home that may not have family nearby, if at all, it is easy to fall into digital deprivation.",
    secondText: "Our platform provides out-of-the-box internet connectivity and features to connect with family, friends and likeminded people.",
    bullets: [
      {
        icon: getSolidIcon("credit-card", "info", 5, "mt-1 flex-none"),
        bold: "Instant connectivity. ",
        text: "Whether you're 1 or 1000 miles away, instantly call loved ones or chat in real time with friends & family."
      },
      {
        icon: getSolidIcon("gift", "info", 5, "mt-1 flex-none"),
        bold: "Ambient monitoring. ",
        text: "Our technology provides an interface to track behaviours, patterns and early stages of degradation for guardians, friends & family members."
      },
      {
        icon: getSolidIcon("heart", "info", 5, "mt-1 flex-none"),
        bold: "Boost independence. ",
        text: "With simple reminders, stimulatory games and daily tasks our platform helps to improve mental health and independence of people in the dark."
      }
    ],
    thirdText: "There are thousands of individuals suffering from digital deprivation but they need a reason to be connected and use technology. Our platform bridges that gap, providing a simple way to feel that human connection.",
    challenge: {
      title: "Not sure if they'll understand?",
      text: "Don't worry, we understand that some people may be hesitant or unsure. We have a team dedicated to helping vulnerable, isolated people to get started who are available 24/7. Oh, and everything will be delivered in the post ready-to-go. Simply plug in and you're away!"
    }
  },
  {
    header: "Tackle loneliness",
    subheader: "Nobody is alone with our platform",
    image: "/img/graphics/screenshots/community.svg",
    firstText: "It took a global pandemic for the world to realise the importance of mental health and human interaction. Our platform is supporting lonely and isolated people that are suffering.",
    secondText: "Tens of thousands of calls every week are made by lonely people to helplines, charities and organisations simply because they are lonely and need someone to talk to. This is a real problem that we're solving with a simple solution.",
    bullets: [
      {
        icon: getSolidIcon("rocket", "info", 5, "mt-1 flex-none"),
        bold: "Human interaction. ",
        text: "At any time of day or night our platform gives users the ability to connect and communicate with people with their voice or on the web."
      },
      {
        icon: getSolidIcon("chart-bar", "info", 5, "mt-1 flex-none"),
        bold: "Likeminded people. ",
        text: "Our intelligent platform helps lonely or isolated people to connect with likeminded people in the local area or across the world, making a virtual connecting into a real life one."
      },
      {
        icon: getSolidIcon("light", "info", 5, "mt-1 flex-none"),
        bold: "Open communication. ",
        text: "Users can instant message, direct video call or group call with others. Our platform is designed to be simple and intuitive, even for the most vulnerable."
      }
    ],
    thirdText: "Our technology is designed not for attention, but for human connection. We believe in technology with purpose and there is nothing more important that our fellow people.",
    challenge: {
      title: "Worried about privacy?",
      text: "Our platform doesn't listen, learn or record from your voice. You can be sure that your privacy is protected with us."
    }
  },
  {
    header: "Stay in touch",
    subheader: "Feel closer to a love one from a distance",
    image: "/img/graphics/screenshots/monitor.svg",
    firstText: "As a friend, loved one or guardian of an isolated or vulnerable person you can monitor, connect with and stay in touch with them easily.",
    secondText: "We provide actionable insights to track behaviours over time as well as an intuitive platform to easily connect with them.",
    bullets: [
      {
        icon: getSolidIcon("stack", "info", 5, "mt-1 flex-none"),
        bold: "Monitor. ",
        text: "Monitor behaviours of your loved one and easily create actions to encourage them to stay active, healthy and happy."
      },
      {
        icon: getSolidIcon("receipt-pct", "info", 5, "mt-1 flex-none"),
        bold: "Foresight.",
        text: "Our platform provides various ways for your loved one to stay stimulated and engaged. As a guardian, you can track if any patterns change over time."
      },
      {
        icon: getSolidIcon("chart-presentation-line", "info", 5, "mt-1 flex-none"),
        bold: "Connect. ",
        text: "At any time of day or night and on any device, you can quickly and easily message or video call with your connected user,."
      }
    ],
    thirdText: "We provide a simple, affordable and easy-to-use platform to bring you closer to a loved one.",
    challenge: {
      title: "Not ready yet? Take a look first",
      text: "We're so confident of our platform and we'd love to show it to you. Sign up today for a free 3 months trial!"
    }
  },
]

export default function Value() {

  const [selectedIndustry, setSelectedIndustry] = useState('Hospitality');

  function handleIndustryClick(e: any, args: string[]) {
    e.preventDefault();
    const [industry] = args;
    setSelectedIndustry(industry);
    const selectedElement = document.getElementById("industry-sections");
    if (selectedElement != null) selectedElement.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <Navbar />

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                Whilst our technology our ambient, the value we deliver is tangible.
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  We strive to create long-term, sustainable value for our customers and end users. You can see some examples of how we do this below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      {/* Value */}
      <div className="relative isolate overflow-hidden bg-white px-6 pt-2 sm:pt-4 pb-16 sm:pb-20 lg:overflow-visible lg:px-0">

        <div className="mx-auto max-w-4xl text-center mt-0 lg:mt-20">
          <h2 className="text-4xl font-bold leading-7 text-info">Value</h2>
          <p className="mt-4 text-gray-600">Choose an industry to see specific value propositions</p>
        </div>

        {/* Choose an industry */}
        <div className="max-w-lg mx-auto grid grid-cols-1 sm:grid-cols-3 gap-y-2 sm:gap-y-0 gap-x-6 mt-10 mb-20">
          <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Hospitality" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Hospitality"]}>
            <BuildingOffice2Icon className="h-6 w-6 text-primary mx-auto mb-2" aria-hidden="true" />
            Hospitality
          </Button>
          <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Health" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Health"]}>
            <HeartIcon className="h-6 w-6 text-danger mx-auto mb-2" aria-hidden="true" />
            Health
          </Button>
          <Button type="button" className={`hover:bg-slate-100 rounded-lg px-2 py-4 justify-center align-center ${selectedIndustry === "Home" && "bg-slate-100"}`} onClick={handleIndustryClick} onClickArgs={["Home"]}>
            <HomeModernIcon className="h-6 w-6 text-success mx-auto mb-2" aria-hidden="true" />
            Home
          </Button>
        </div>

        <div className="h-28 border-t-2" id="industry-sections" />

        {selectedIndustry === "Hospitality" && hospitalityData.map((value, index) => (
          <div key={index} id="Hospitality-section" className={`mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 pb-10 lg:pb-32 ${index > 0 && "pt-32 border-t-2"}`}>
            {/* <Fragment key={index}> */}

            {/* Header */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-info">{value.header}</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{value.subheader}</h1>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    {value.firstText}
                  </p>
                </div>
              </div>
            </div>

            {/* Image */}
            <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-20 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-lg ring-1 ring-gray-400/10 sm:w-[57rem]"
                src={value.image}
                alt=""
              />
            </div>

            {/* Text content */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <p>
                    {value.secondText}
                  </p>
                  <ul className="mt-8 space-y-8 text-gray-600">
                    {value.bullets.map((bullet, idx) => (
                      <li className="flex gap-x-3" key={idx}>
                        {bullet.icon}
                        <span>
                          <strong className="font-semibold text-gray-900">{bullet.bold}</strong>{bullet.text}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-8">
                    {value.thirdText}
                  </p>
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{value.challenge.title}</h2>
                  <p className="mt-6">
                    {value.challenge.text}
                  </p>
                </div>
              </div>
            </div>
            {/* </Fragment> */}
          </div>
        ))}

        {selectedIndustry === "Health" && healthData.map((value, index) => (
          <div key={index} id="Health-section" className={`mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 pb-10 lg:pb-32 ${index > 0 && "pt-32 border-t-2"}`}>
            {/* <Fragment key={index}> */}

            {/* Header */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-info">{value.header}</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{value.subheader}</h1>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    {value.firstText}
                  </p>
                </div>
              </div>
            </div>

            {/* Image */}
            <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-20 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                src={value.image}
                alt=""
              />
            </div>

            {/* Text content */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <p>
                    {value.secondText}
                  </p>
                  <ul className="mt-8 space-y-8 text-gray-600">
                    {value.bullets.map((bullet, idx) => (
                      <li className="flex gap-x-3" key={idx}>
                        {bullet.icon}
                        <span>
                          <strong className="font-semibold text-gray-900">{bullet.bold}</strong>{bullet.text}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-8">
                    {value.thirdText}
                  </p>
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{value.challenge.title}</h2>
                  <p className="mt-6">
                    {value.challenge.text}
                  </p>
                </div>
              </div>
            </div>
            {/* </Fragment> */}
          </div>
        ))}

        {selectedIndustry === "Home" && homeData.map((value, index) => (
          <div key={index} id="Home-section" className={`mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 pb-10 lg:pb-32 ${index > 0 && "pt-32 border-t-2"}`}>
            {/* <Fragment key={index}> */}

            {/* Header */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-info">{value.header}</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{value.subheader}</h1>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    {value.firstText}
                  </p>
                </div>
              </div>
            </div>

            {/* Image */}
            <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-20 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                src={value.image}
                alt=""
              />
            </div>

            {/* Text content */}
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <p>
                    {value.secondText}
                  </p>
                  <ul className="mt-8 space-y-8 text-gray-600">
                    {value.bullets.map((bullet, idx) => (
                      <li className="flex gap-x-3" key={idx}>
                        {bullet.icon}
                        <span>
                          <strong className="font-semibold text-gray-900">{bullet.bold}</strong>{bullet.text}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="mt-8">
                    {value.thirdText}
                  </p>
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{value.challenge.title}</h2>
                  <p className="mt-6">
                    {value.challenge.text}
                  </p>
                </div>
              </div>
            </div>
            {/* </Fragment> */}
          </div>
        ))}

      </div>

      <Banner />
      <Footer />
    </>
  )
}
