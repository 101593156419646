import getButtonStyle from "../../utils/styles";
import Link from "../layout/Link";

export default function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden py-32 sm:py-48 bg-dark"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-xl lg:max-w-3xl text-center">
          <h2 className="font-display tracking-tight text-primarygotham-bold text-3xl sm:text-4xl md:text-5xl gotham-bold text-primary">
            What are you waiting for?
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-200">
            Whether it's providing personalised experiences, wanting to boost revenue, bringing independence to senior living or something custom, to suit your specific business needs, we can help. So, start your journey today and say hello to audico!
          </p>
          <Link className={`${getButtonStyle("solid", "info")} mt-10`} href="/contact">
            Get in touch!
          </Link>
        </div>
      </div>
    </section>
  )
}
