import { ChartBarSquareIcon, CreditCardIcon, EllipsisHorizontalCircleIcon, GiftIcon, HeartIcon, LightBulbIcon, PresentationChartLineIcon, ReceiptPercentIcon, RocketLaunchIcon, Square3Stack3DIcon } from "@heroicons/react/24/solid"

export function getIcon(icon: string, size: number) {
  switch (icon) {
    case "linkedin": 
      return (      
        <svg width="500" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="500" preserveAspectRatio="xMidYMid meet" version="1.0" className={`h-${size} w-${size}`}>
          <defs>
            <clipPath id="06f159b394">
              <path d="M 25.457031 25.457031 L 349.457031 25.457031 L 349.457031 349.457031 L 25.457031 349.457031 Z M 25.457031 25.457031 " clip-rule="nonzero"/>
            </clipPath>
          </defs>
          <g clip-path="url(#06f159b394)">
            <path fill="#ffffff" d="M 322.390625 25.457031 L 52.6875 25.457031 C 37.777344 25.457031 25.78125 37.613281 25.78125 52.363281 L 25.78125 322.554688 C 25.78125 337.464844 37.9375 349.457031 52.6875 349.457031 L 322.230469 349.457031 C 337.140625 349.457031 349.132812 337.300781 349.132812 322.554688 L 349.132812 52.363281 C 349.296875 37.613281 337.300781 25.457031 322.390625 25.457031 Z M 121.574219 292.730469 L 73.757812 292.730469 L 73.757812 149.125 L 121.574219 149.125 Z M 97.585938 131.785156 C 82.511719 131.785156 70.355469 119.628906 70.355469 104.554688 C 70.355469 89.480469 82.511719 77.324219 97.585938 77.324219 C 112.660156 77.324219 124.8125 89.480469 124.8125 104.554688 C 124.8125 119.628906 112.660156 131.785156 97.585938 131.785156 Z M 301.484375 292.730469 L 253.507812 292.730469 L 252.371094 207.476562 C 252.371094 207.476562 253.507812 184.785156 228.707031 184.945312 C 203.910156 185.109375 200.507812 207.476562 200.507812 207.476562 L 200.507812 292.730469 L 152.207031 292.730469 L 152.207031 149.125 L 198.5625 149.125 L 198.5625 168.902344 C 198.5625 168.902344 200.507812 162.417969 211.203125 154.800781 C 221.902344 147.179688 239.566406 145.5625 247.1875 145.886719 C 254.804688 146.210938 265.175781 144.914062 282.519531 156.90625 C 301.160156 169.710938 301.644531 203.261719 301.644531 203.261719 Z M 301.484375 292.730469 " fillOpacity="1" fillRule="nonzero"/>
          </g>
        </svg>
      )
    case "facebook":
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      )
    case "instagram":
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      )
    case "twitter":
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    case "github":
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      )
    case "youtube":
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      )

    default:
      return (        
        <svg fill="currentColor" className={`h-${size} w-${size}`}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      );
  }
}

export function getSolidIcon(text: string | undefined, colour: string, size: number, className?: string) {
  if (text === "credit-card") return <CreditCardIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "gift") return <GiftIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "heart") return <HeartIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "rocket") return <RocketLaunchIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "chart-bar") return <ChartBarSquareIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "light") return <LightBulbIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "stack") return <Square3Stack3DIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "receipt-pct") return <ReceiptPercentIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  if (text === "chart-presentation-line") return <PresentationChartLineIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
  return <EllipsisHorizontalCircleIcon className={`h-${size} w-${size} text-${colour} inline-block ${className !== undefined && className}`} />
}
