import Navbar from '../landing/Navbar'
import Hero from '../landing/Hero'
import LogoCloud from '../landing/LogoCloud'
import Banner from '../layout/Banner'
import { Footer } from '../landing/Footer'



export default function Partners() {

  return (
    <>
      <Navbar/>

      {/* Hero */}
      <Hero>
        <div className="relative isolate -z-10 -top-[110px] overflow-hidden">
          <div className="mx-auto px-0 lg:pt-72 lg:pb-80 md:pt-64 md:pb-48 sm:pt-64 sm:pb-32 pt-64 pb-16">
            <div className="mx-auto lg:mx-0 grid grid-cols-1 gap-y-6">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                We pride ourselves on a thriving & collaborative partner community.
              </h1>
              <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1">
                <p className="text-lg leading-8 text-slate-200">
                  Ranging from multinational corporations to innovative small businesses, we love to work with people and companies that share our passion.
                </p>
              </div>
            </div>
          </div>
        </div>        
      </Hero>

      {/* Timeline */}
      <div className="bg-white">

        <main className="isolate">
          <LogoCloud showHighlight={false} size={32}/>

          <div className="flex justify-center mb-32">
            <a href="/contact" className="bg-info px-8 py-3 mx-10 leading-6 text-white drop-shadow rounded-2xl sm:rounded-full text-xl font-bold hover:bg-fuchsia-800 text-center" target="_blank">
              Want to join our partner community? Get in touch!
            </a>
          </div>
          
        </main>
      </div>
      <Banner />
      <Footer />
    </>
  )
}
