import Waves from './Waves'

export default function Hero({children}: {children?: React.ReactNode}) {
  return (
    <div className="relative isolate -top-[110px] bg-gradient-to-r from-info to-primary background-animate">
      
      {/* Content */}
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            {children}
          </div>
        </div>
      </div>

      {/* Waves */}
      <Waves />
    </div>
  )
}
