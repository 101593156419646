import Banner from "../layout/Banner";
import Hero from "./Hero";
import Navbar from "./Navbar";
import PrimaryFeatures from "./PrimaryFeatures";
import SecondaryFeatures from "./SecondaryFeatures";
import CallToAction from "./CallToAction";
import Testimonials from "./Testimonials";
import { Footer } from "./Footer";
import LogoCloud from "./LogoCloud";
import Stats from "./Stats";
import getButtonStyle from "../../utils/styles";
import { useEffect, useState } from "react";

export default function Landing() {

  const heroTextOptions = ["Hospitality", "Senior Living", "Hotels", "Life at Home", "Assistance", null]
  const [heroText, setHeroText] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setHeroText(heroText < heroTextOptions.length - 1 ?  heroText + 1 : 0)
    }, 3000);
    return () => clearInterval(timer);
  }, [heroText, heroTextOptions.length]);

  return (
    <div className="bg-white">
      <Navbar />
      <Hero>
        {/* Text */}
        <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl lg:pt-0 lg:pb-72 md:pt-40 md:pb-8 sm:pt-40 sm:pb-8 pt-40 pb-8">

          {/* Header */}
          {heroTextOptions[heroText] === null ? (
            <h1 className="mx-auto max-w-4xl font-bold font-display text-4xl tracking-tight text-slate-200 sm:text-6xl">
              Say hello
              <br/> 
              to{' '}
              <br/>
              <span className="relative whitespace-nowrap text-white">
                <svg
                  aria-hidden="true"
                  viewBox="0 0 418 42"
                  className="absolute left-0 top-2/3 h-[0.58em] w-full fill-slate-300/50"
                  preserveAspectRatio="none"
                >
                  <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                </svg>
                <span className="relative gotham-bold">audico</span>
              </span>
            </h1>
          ) : (
            <h1 className="mx-auto max-w-4xl font-bold font-display text-4xl tracking-tight text-slate-200 sm:text-6xl">
              Welcome to
              <br/>
              the future of{' '}
              <br/>
              <span className="relative whitespace-nowrap text-white">
                <svg
                  aria-hidden="true"
                  viewBox="0 0 418 42"
                  className="absolute left-0 top-2/3 h-[0.58em] w-full fill-slate-300/50"
                  preserveAspectRatio="none"
                >
                  <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                </svg>
                <span className="relative gotham-bold">{heroTextOptions[heroText]}</span>
              </span>
            </h1>
          )}

          {/* Sub-header */}
          <p className="relative mt-6 text-lg leading-8 text-slate-200 sm:max-w-md lg:max-w-none">
            We bring together advanced voice and multi-modal technology to enhance end-user experiences, improve operational efficiency and boost revenue.
          </p>

          {/* Action buttons */}
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/about"
              className={`${getButtonStyle("solid", "dark")} z-50`}
            >
              Find out more
            </a>
            <a href="contact" className={`${getButtonStyle("solid", "white")} z-50`}>
              Get in touch <span aria-hidden="true" className="ml-2">→</span>
            </a>
          </div>
        </div>

        {/* Images */}
        <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0 z-50 lg:pt-32 lg:pb-96 md:pt-0 md:pb-32 sm:pt-0 sm:pb-20 pt-0 pb-28">
          <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80 z-50">
            <div className="relative">
              <img
                src="img/graphics/stock/dinner-table.jpg"
                alt=""
                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
          <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36 z-50">
            <div className="relative">
              <img
                src="img/graphics/stock/room-service.jpg"
                alt=""
                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="relative">
              <img
                src="img/graphics/stock/hotel.jpg"
                alt=""
                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
          <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0 z-50">
            <div className="relative">
              <img
                src="img/graphics/stock/elderly.jpg"
                alt=""
                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="relative">
              <img
                src="img/graphics/stock/elderly-at-home.jpg"
                alt=""
                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
        </div>
      </Hero>
      <LogoCloud/>
      <PrimaryFeatures/>
      <SecondaryFeatures/>
      <Stats/>
      <CallToAction/>
      <Testimonials/>
      <Banner />
      <Footer/>
    </div>
  );
}