import { SES, AWSError } from 'aws-sdk';
import { SendTemplatedEmailRequest, SendTemplatedEmailResponse } from 'aws-sdk/clients/ses';


function sendTemplatedEmailSes(recipient: string, subject: string, body: string) {

  // Instantiate SES
  const ses = new SES({region: process.env.REACT_APP_REGION, accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY});

  // Build variables
  const sender = `Contact form - ${subject} - <support@audico.co.uk>`;
  const template = "audico-contact-us";
  
  const params: SendTemplatedEmailRequest = {
      Source: sender,
      Destination: {
          ToAddresses: [
              "support@audico.co.uk"
          ]
      },
      Template: template,
      TemplateData: `{ "email": "${recipient}", "body": "${body}" }`
  }
  
  let result = true;
  ses.sendTemplatedEmail(params, (err: AWSError, data: SendTemplatedEmailResponse) => {
    if (err !== null) {
      console.log(err, err.stack)
      result = false;
    }
  });

  return result;

}

export default sendTemplatedEmailSes;