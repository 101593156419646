
export default function Waves({orientation}: {orientation?: "up" | "down"}) {
  return orientation === "up" ? (
    <svg viewBox='0 0 1440 340' xmlns="http://www.w3.org/2000/svg" className="transition delay-150 absolute bottom-0">
      <path d="M 0,400 C 0,400 0,100 0,100 C 24.31233587855685,108.86169353762536 48.6246717571137,117.72338707525071 76,111 C 103.3753282428863,104.27661292474929 133.81364885010206,81.9681452366225 167,70 C 200.18635114989794,58.031854763377495 236.12073284247793,56.404031978259255 264,64 C 291.87926715752207,71.59596802174075 311.70341977998623,88.41572685034049 344,88 C 376.29658022001377,87.58427314965951 421.06558803757736,69.93306062037877 452,73 C 482.93441196242264,76.06693937962123 500.03422806970445,99.85203066814447 531,103 C 561.9657719302955,106.14796933185553 606.7974996836049,88.65881670704341 639,90 C 671.2025003163951,91.34118329295659 690.7757731958762,111.5127025036819 714,109 C 737.2242268041238,106.4872974963181 764.0994075328899,81.29037327822904 798,79 C 831.9005924671101,76.70962672177096 872.8265966725638,97.32580438340192 909,110 C 945.1734033274362,122.67419561659808 976.5942057768555,127.40640918816322 1002,113 C 1027.4057942231445,98.59359081183678 1046.796580220014,65.0485588639452 1075,63 C 1103.203419779986,60.95144113605479 1140.2194733430892,90.39935535605595 1176,104 C 1211.7805266569108,117.60064464394405 1246.32552640763,115.3540197118311 1271,111 C 1295.67447359237,106.6459802881689 1310.4784210263915,100.18456579661968 1337,98 C 1363.5215789736085,95.81543420338032 1401.7607894868042,97.90771710169017 1440,100 C 1440,100 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="0.4" className="transition-all duration-300 ease-in-out delay-150 path-0"></path>
      <path d="M 0,400 C 0,400 0,200 0,200 C 23.908467283093977,187.3645063632414 47.81693456618795,174.72901272648278 75,181 C 102.18306543381205,187.27098727351722 132.64072901834214,212.44845545731022 167,220 C 201.35927098165786,227.55154454268978 239.62014936044346,217.47716544427638 270,210 C 300.37985063955654,202.52283455572362 322.8786735398841,197.64288276558432 357,190 C 391.1213264601159,182.35711723441568 436.86515648001995,171.95130349338638 463,178 C 489.13484351998005,184.04869650661362 495.6607005400358,206.55190326087015 526,205 C 556.3392994599642,203.44809673912985 610.4920413598363,177.84108346313297 644,184 C 677.5079586401637,190.15891653686703 690.3711340206187,228.08376288659795 716,232 C 741.6288659793813,235.91623711340205 780.0234225576892,205.82386499047527 814,192 C 847.9765774423108,178.17613500952473 877.535175748624,180.620777151501 909,184 C 940.464824251376,187.379222848499 973.8358744478151,191.69302640352083 1004,192 C 1034.164125552185,192.30697359647917 1061.1213264601158,188.6071172344157 1090,184 C 1118.8786735398842,179.3928827655843 1149.6788197117216,173.8785046588164 1176,170 C 1202.3211802882784,166.1214953411836 1224.1633946929978,163.87886413031865 1255,172 C 1285.8366053070022,180.12113586968135 1325.6676015162864,198.60603881990897 1358,205 C 1390.3323984837136,211.39396118009103 1415.1661992418567,205.6969805900455 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="0.53" className="transition-all duration-300 ease-in-out delay-150 path-1"></path>
      <path d="M 0,400 C 0,400 0,300 0,300 C 25.464528376871954,300.2274960938793 50.92905675374391,300.45499218775865 81,303 C 111.07094324625609,305.54500781224135 145.74830136189635,310.40752734284473 176,317 C 206.25169863810365,323.59247265715527 232.07773779867068,331.9148984408625 261,336 C 289.9222622013293,340.0851015591375 321.9407474434212,339.93287889370555 353,324 C 384.0592525565788,308.06712110629445 414.15927242764457,276.35358598431543 447,266 C 479.84072757235543,255.64641401568457 515.4221628460007,266.6527771690328 548,279 C 580.5778371539993,291.3472228309672 610.1520761883525,305.0353053395533 640,300 C 669.8479238116475,294.9646946604467 699.9695324005891,271.20600147275405 730,270 C 760.0304675994109,268.79399852724595 789.9697942092911,290.14068876943054 821,299 C 852.0302057907089,307.85931123056946 884.1512907622466,304.2312434495239 909,293 C 933.8487092377534,281.7687565504761 951.4250427417223,262.93433743247397 977,267 C 1002.5749572582777,271.06566256752603 1036.1485382708643,298.0314068205802 1072,301 C 1107.8514617291357,303.9685931794198 1145.9808041748197,282.9400352852053 1174,286 C 1202.0191958251803,289.0599647147947 1219.928245029856,316.2084520385986 1248,313 C 1276.071754970144,309.7915479614014 1314.3062157057554,276.2261565604004 1348,269 C 1381.6937842942446,261.7738434395996 1410.8468921471222,280.88692171979983 1440,300 C 1440,300 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="1" className="transition-all duration-300 ease-in-out delay-150 path-2"></path>
    </svg>
  ) : (
    <svg viewBox="0 0 1440 340" xmlns="http://www.w3.org/2000/svg" className="transition duration-150 absolute top-0">
      <path d="M 0,400 C 0,400 0,100 0,100 C 25.760429834309107,115.32597578369543 51.520859668618215,130.65195156739085 83,135 C 114.47914033138179,139.34804843260915 151.67699115983626,132.71816951413203 188,116 C 224.32300884016374,99.28183048586797 259.7711756920367,72.47537037608099 284,79 C 308.2288243079633,85.52462962391901 321.23830607201694,125.38034898154405 344,137 C 366.76169392798306,148.61965101845595 399.2756000198956,132.00323369774281 436,127 C 472.7243999801044,121.99676630225717 513.6592938484009,128.60671622748458 544,118 C 574.3407061515991,107.39328377251542 594.0872245865007,79.56990139231884 624,72 C 653.9127754134993,64.43009860768116 693.9918078055964,77.11367820324007 725,91 C 756.0081921944036,104.88632179675993 777.9455441911134,119.9753857947209 804,119 C 830.0544558088866,118.0246142052791 860.2260154299496,100.98477861787624 894,96 C 927.7739845700504,91.01522138212376 965.1503940890882,98.08549973377409 1000,108 C 1034.8496059109118,117.91450026622591 1067.1724082136975,130.6732224470274 1096,119 C 1124.8275917863025,107.32677755297262 1150.1599730561222,71.22161047811639 1176,64 C 1201.8400269438778,56.77838952188362 1228.1876995618131,78.44033564050712 1255,80 C 1281.8123004381869,81.55966435949288 1309.0892286966248,63.0170469598551 1340,63 C 1370.9107713033752,62.9829530401449 1405.4553856516877,81.49147652007245 1440,100 C 1440,100 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="0.4" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path>
      <path d="M 0,400 C 0,400 0,200 0,200 C 33.134568166159404,186.19457469031818 66.26913633231881,172.38914938063635 92,180 C 117.73086366768119,187.61085061936365 136.05802283688416,216.63797716777273 163,225 C 189.94197716311584,233.36202283222727 225.49877232014455,221.05894194827275 262,211 C 298.50122767985545,200.94105805172725 335.94688788253785,193.1262550391363 369,194 C 402.05311211746215,194.8737449608637 430.71367614970416,204.4360378951819 458,210 C 485.28632385029584,215.5639621048181 511.19840751864547,217.12959338013607 541,212 C 570.8015924813545,206.87040661986393 604.4926937757141,195.04558858427382 636,197 C 667.5073062242859,198.95441141572618 696.8308173784978,214.68805228276875 724,225 C 751.1691826215022,235.31194771723125 776.1840367102945,240.20220228465112 803,221 C 829.8159632897055,201.79779771534888 858.4330357803235,158.5031385786268 888,165 C 917.5669642196765,171.4968614213732 948.0838201684107,227.7852434008417 976,233 C 1003.9161798315893,238.2147565991583 1029.2316835460338,192.3558878180065 1064,184 C 1098.7683164539662,175.6441121819935 1142.989445647455,204.79120532713227 1177,212 C 1211.010554352545,219.20879467286773 1234.8105338641467,204.47929087346438 1261,200 C 1287.1894661358533,195.52070912653562 1315.7684188959581,201.29163117901018 1346,203 C 1376.2315811040419,204.70836882098982 1408.1157905520208,202.3541844104949 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="0.53" className="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 200)"></path>
      <path d="M 0,400 C 0,400 0,300 0,300 C 23.59595870794302,300.96430199875664 47.19191741588604,301.9286039975133 80,307 C 112.80808258411396,312.0713960024867 154.82828904439884,321.24988600870347 185,318 C 215.17171095560116,314.75011399129653 233.49492640651846,299.07185196767284 259,294 C 284.50507359348154,288.92814803232716 317.1920053295273,294.46270612060516 346,303 C 374.8079946704727,311.53729387939484 399.7370522753725,323.0773235499063 437,317 C 474.2629477246275,310.9226764500937 523.8597855689826,287.22799967976977 555,275 C 586.1402144310174,262.77200032023023 598.8238054486969,262.0106777310149 622,276 C 645.1761945513031,289.9893222689851 678.8449926362297,318.7292893961708 710,325 C 741.1550073637703,331.2707106038292 769.7962240063841,315.0721646843018 802,312 C 834.2037759936159,308.9278353156982 869.9701113382339,318.982051866622 903,320 C 936.0298886617661,321.017948133378 966.3233306406805,312.99962784921007 994,307 C 1021.6766693593195,301.00037215078993 1046.7365660990442,297.01943673653767 1081,294 C 1115.2634339009558,290.98056326346233 1158.7304049631427,288.9226252046394 1184,299 C 1209.2695950368573,309.0773747953606 1216.3418140483845,331.2900624449048 1246,329 C 1275.6581859516155,326.7099375550952 1327.9023388433186,299.9171250157415 1364,291 C 1400.0976611566814,282.0828749842585 1420.0488305783406,291.04143749212926 1440,300 C 1440,300 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#ffffff" fillOpacity="1" className="transition-all duration-300 ease-in-out delay-150 path-2" transform="rotate(-180 720 200)"></path>
    </svg>
  )
}

Waves.defaultProps = {
  orientation: "up"
}