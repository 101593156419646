const partnerLogos = [
  ['Ascot', 'ascot.svg', "https://www.ascot.com/"],
  ['Bridge Group', 'bridge.svg', "https://www.groupe-bridge.com/"],
  ['Elavon', 'elavon.svg', "https://www.elavon.co.uk/"],
  ['The Digital Line', 'tdl.svg', "https://www.thedigitalline.co.uk/"],
  ['Halo', 'halo.svg', "https://www.halotechlab.com/"],
  ['Morris Care', 'morris.svg', "https://www.morriscare.co.uk/"],
  ['Paris La Defense Arena', 'plda.svg', "https://www.parisladefense-arena.com/"],
  ['The Royal Air Forces Association', 'rafa.svg', "https://rafa.org.uk/"],
  ['Royal Garden Hotel', 'rgh.svg', "https://www.royalgarden.net/"],
  ['Alexa', 'alexa.svg', "https://www.alexa.com/"],
  ['Canford Care', 'cc.svg', "https://canfordhealthcare.co.uk/"],
  ['Severn Hospice', 'severn.svg', "https://www.severnhospice.org.uk/"],
  ['Apartments at No.16', 'apartments-16.svg', "https://www.number16apartments.co.uk/"],
  ['University of Surrey', 'uos.svg', "https://www.surrey.ac.uk/"],
  ['LISNR', 'lisnr.svg', "https://lisnr.com/"],
]

export default partnerLogos;