import partnerLogos from "../../utils/logos";


export default function LogoCloud({showHighlight, size}: {showHighlight?: boolean, size?: number}) {

  function updateLogo(idx: number, size: number | undefined, grayscale: boolean) {
    const img = document.getElementById(`logo-${idx}`) as HTMLImageElement;
    img.className = `col-span-2 max-h-${size} w-full object-contain lg:col-span-1 ${grayscale && "grayscale"}`
  }

  return (
    <div className="bg-white my-32 mt-8 sm:my-48 sm:mt-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-3 sm:grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5 place-items-center">
          {partnerLogos.map((logo, idx) => (
            <a className="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-150 duration-300" href={logo[2]} key={idx} target="_blank" rel="noreferrer" onMouseEnter={() => updateLogo(idx, size, false)} onMouseLeave={() => updateLogo(idx, size, true)}>
              <img
                id={`logo-${idx}`}
                className={`col-span-2 max-h-${size} w-full object-contain lg:col-span-1 grayscale`}
                src={`/img/graphics/partners/${logo[1]}`}
                alt={logo[0]}
              />
            </a>
          ))}
        </div>
        {showHighlight && (
          <div className="mt-16 flex justify-center">
            <p className="relative rounded-full bg-slate-100 px-4 py-1.5 text-sm leading-6 text-gray-600 drop-shadow text-center">
              <span className="hidden md:inline">Trusted by partners and clients across multiple industries in Europe.</span>
              <a href="/contact" className="font-semibold text-info" target="_blank">
                <span className="absolute inset-0" aria-hidden="true" /> Want to join? {' '}
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        )}
        {/* {showHighlight && (
          <div className="mt-16 flex justify-center">
            <p className="relative rounded-full bg-slate-100 px-4 py-1.5 text-sm leading-6 text-gray-600 drop-shadow text-center">
              <span className="hidden md:inline">Our platform has handled over 30,000 requests from thousands of individuals.</span>
              <a href="/success" className="font-semibold text-info" target="_blank">
                <span className="absolute inset-0" aria-hidden="true" /> Read our customer stories{' '}
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        )} */}
      </div>
    </div>
  )
}

LogoCloud.defaultProps = {
  showHighlight: true,
  size: 20
}
