import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import getButtonStyle from '../../utils/styles'
import Link from '../layout/Link'

export function Navlinks({links, colour}: {links: string[][], colour: string}) {
  let [hoveredIndex, setHoveredIndex] = useState<number|null>(null)

  return <>{
    links.map(([label, href], index) => (
      <Link 
        key={index}
        href={href} 
        className={`${getButtonStyle("solid", colour)} mx-0 relative -my-2 text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]`}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <AnimatePresence>
          {hoveredIndex === index && (
            <motion.span
              className="absolute inset-0 rounded-lg"
              layoutId="hoverBackground"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.15 } }}
              exit={{
                opacity: 0,
                transition: { duration: 0.15, delay: 0.2 },
              }}
            />
          )}
        </AnimatePresence>
        <span className="relative z-10">{label}</span>
      </Link>
    ))
  }</>
}
