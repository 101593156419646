interface StringLookup {
  [key: string]: string;
}

interface StringLookupDeep {
  [key: string]: StringLookup;
}

const buttonBaseStyles: StringLookup = {
  solid:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
}

const buttonVariantStyles: StringLookupDeep = {
  solid: {
    cyan: 'relative overflow-hidden bg-cyan-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-cyan-600 active:text-white/80 before:transition-colors',
    white:
      'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    gray: 'bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80',
    dark: 'bg-dark-blue text-white hover:bg-dark active:bg-dark active:text-white/80',
    info: 'bg-info text-white hover:bg-fuchsia-800 active:bg-fuchsia-800 active:text-white/80',
    success: 'bg-success text-white hover:bg-green-500 active:bg-green-500 active:text-white/80',
    danger: 'bg-danger text-white hover:bg-red-600 active:bg-red-600 active:text-white/80',
    primary: 'bg-primary text-white hover:bg-sky-600 active:bg-sky-600 active:text-white/80',
    warning: 'bg-warning text-black hover:bg-amber-500 active:bg-amber-500 active:text-black/80',
    hoverPrimary: 'bg-transparent text-white hover:bg-primary/10 active:bg-primary/10 active:text-white/80',
    hoverInfo: 'bg-transparent text-white hover:bg-info/10 active:bg-info/10 active:text-white/80',
    hoverDark: 'bg-transparent text-white hover:bg-dark/10 active:bg-dark/10 active:text-white/80',
    hoverDanger: 'bg-transparent text-white hover:bg-danger/10 active:bg-danger/10 active:text-white/80',
    hoverLight: 'bg-transparent hover:bg-slate-100 active:bg-slate-100 active:text-white/80',
    hoverTransparent: 'bg-transparent hover:bg-slate-100/20 active:bg-slate-100/20 active:text-white/80',
    glass: 'bg-slate-100/20 text-white hover:bg-white active:bg-white active:text-black hover:text-black',
    transparent: 'bg-transparent text-white hover:bg-white',
    hoverGlass: 'bg-transparent text-white hover:bg-slate-100/20 hover:text-white',
    none: 'border-none text-gray-700 hover:bg-slate-100 active:bg-gray-100 active:text-gray-700/80',
    muted: "border-none"
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80',
    none: 'border-none text-gray-700 hover:bg-slate-100 active:bg-gray-100 active:text-gray-700/80',
  },
}

function getButtonStyle(variant: string, colour: string) {
  return `${buttonBaseStyles[variant]} ${buttonVariantStyles[variant][colour]}`
}

export default getButtonStyle;