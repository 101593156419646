// python3
// from datetime import datetime
// a = "2023-08-17"
// datetime.strptime(a, "%Y-%m-%d").timestamp()

const press = [
  {
    id: "sap-audico-interview",
    title: 'Making Technology human through AI',
    href: 'https://sapvideoa35699dc5.hana.ondemand.com/?entry_id=1_fode8rp3',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2023',
    datetime: 1702425600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/sap-audico-interview.jpg`,
    type: "article"
  },
  {
    id: "tdl-soundbites-1",
    title: 'Tell us about audico and what is does?',
    href: 'https://www.youtube.com/embed/UbK5PfYmL0o',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2023',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-how-it-works.jpg`,
    type: "video"
  },
  {
    id: "tdl-soundbites-2",
    title: 'What are the benefits of audico for the hospitality sector?',
    href: 'https://www.youtube.com/embed/-uJYbRKGt0M',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2023',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-how-it-works.jpg`,
    type: "video"
  },
  {
    id: "tdl-soundbites-3",
    title: 'What are the benefits of audico for the senior living sector?',
    href: 'https://www.youtube.com/embed/GkEaLuM4Yhs',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2023',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-how-it-works.jpg`,
    type: "video"
  },
  {
    id: "tdl-soundbites-4",
    title: 'How has technology like audico shaped how people live their lives?',
    href: 'https://www.youtube.com/embed/jW4gNgwuqOs',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2023',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-how-it-works.jpg`,
    type: "video"
  },
  {
    id: "tcpa-2024",
    title: 'The Card & Payments Awards 2024',
    href: 'https://cardandpaymentsawards.com/thecardandpaymentsawards2024/en/page/2024-finalists',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Feb 2024',
    datetime: 1706745600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/tcpa-2024.jpg`,
    type: "article"
  },
  {
    id: "audico-vocalime",
    title: 'From A to V, we have you covered!',
    href: 'https://www.thedigitalline.co.uk/2023/10/18/from-a-to-v-we-have-you-covered/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 23',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/audico-vocalime.jpg`,
    type: "article"
  },
  {
    id: "tdl-is-voice-new-player-in-hospitality",
    title: 'Is Voice the new player in Hospitality?',
    href: 'https://www.thedigitalline.co.uk/2023/10/26/is-voice-the-new-player-in-hospitality/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 23',
    datetime: 1701388800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/voice-new-player-hospitality.jpg`,
    type: "article"
  },


  {
    id: "hospa-how-it-works",
    title: 'audico - How it Works',
    href: 'https://www.hospa.org/post/audico-how-it-works',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Oct 2023',
    datetime: 1696114800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-how-it-works.jpg`,
    type: "article"
  },
  {
    id: "elavon-coliseum",
    title: 'Elavon at the forefront of venue business',
    href: 'https://www.coliseum-online.com/elavon-at-the-forefront-of-venue-business/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Sept 2023',
    datetime: 1694559600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/elavon-coliseum.jpg`,
    type: "article"
  },
  {
    id: "hospa-new-voice-hospitality",
    title: 'audico - The New Voice in Hospitality',
    href: 'https://www.hospa.org/post/audico-the-new-voice-in-hospitality',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Sept 2023',
    datetime: 1693522800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hospa-voice-hospitality.jpg`,
    type: "article"
  },
  {
    id: "alexa-morris-care",
    title: 'Pioneering Alexa product @ Morris Care',
    href: 'https://www.morriscare.co.uk/news/weve-been-selected-by-amazon-for-pioneering-alexa-product/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Dec 2022',
    datetime: 1669852800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/alexa-morris-care.jpg`,
    type: "article"
  },
  {
    id: "myhudson-mondo-stadia",
    title: 'Voice ordering with MyHudson covered by MondoStadia',
    href: 'https://issuu.com/mondiale/docs/mdst14_digitallr/88',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Oct 2020',
    datetime: 1601506800,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/myhudson-mondo-stadia.jpg`,
    type: "article"
  },
  {
    id: "caring-face",
    title: 'The caring face of Technology',
    href: 'https://www.thedigitalline.co.uk/2019/02/06/tdl-the-caring-face-of-technology/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Feb 2019',
    datetime: 1549411200,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/caring-face.jpg`,
    type: "article"
  },
  {
    id: "alexa-residential",
    title: 'Alexa in Residential Care',
    href: 'https://www.thedigitalline.co.uk/2018/03/01/tdl-brings-alexa-to-residential-care-home/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Mar 2018',
    datetime: 1519862400,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/alexa-residential.jpg`,
    type: "article"
  },
  {
    id: "tdl-audico",
    title: 'TDL introduces audico',
    href: "https://www.thedigitalline.co.uk/2022/04/10/the-digital-line-tdl-introduces-audico/",
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Apr 2022',
    datetime: 1649545200,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/tdl-audico.jpg`,
    type: "article"
  },
  {
    id: "tdl-audico-solution",
    title: 'audico solution',
    href: "https://www.thedigitalline.co.uk/2023/03/20/audico-solution/",
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Mar 2023',
    datetime: 1679270400,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/tdl-audico-solution.jpg`,
    type: "article"
  },
  {
    id: "audico-rothbury",
    title: 'audico at RAFA Rothbury House',
    href: 'https://www.thedigitalline.co.uk/2023/06/14/tech-for-good-at-rothbury-house/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Jun 2023',
    datetime: 1686697200,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/audico-rothbury.jpg`,
    type: "article"
  },
  {
    id: "seniors-video-call",
    title: 'Seniors embrace video calling',
    href: 'https://www.thedigitalline.co.uk/2020/04/18/seniors-embrace-no-touch-video-calling/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: "Apr 2020",
    datetime: 1587164400,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/seniors-video-call.jpg`,
    type: "article"
  },
  {
    id: "code-call",
    title: 'From code breaker to video call maker',
    href: 'https://www.thedigitalline.co.uk/2020/10/09/from-code-breaker-to-video-call-maker/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Oct 2020',
    datetime: 1602198000,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/code-call.jpg`,
    type: "article"
  },
  {
    id: "digital-lifeline",
    title: 'A digital lifeline - over 1.4 million views!',
    href: 'https://www.youtube.com/embed/U5vwhVDRT_8',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Oct 2019',
    datetime: 1569884400,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/digital-lifeline.jpg`,
    type: "video"
  },
  // {
  //   id: "amazon-asp-europe",
  //   title: 'Amazon launches Alexa Smart Properties for Senior Living in the UK, France, Germany and Italy',
  //   href: 'https://www.thedigitalline.co.uk/2018/03/01/tdl-brings-alexa-to-residential-care-home/',
  //   description:
  //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   author: {
  //     name: 'Michael Foster',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  //   type: "article"
  // },
  {
    id: "audico-ascot",
    title: 'audico for Ascot Racecourse | Alexa Innovators Deep Dive',
    href: 'https://www.youtube.com/embed/u7rItOEaqvo',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Jan 2023',
    datetime: 1672531200,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/audico-ascot.jpg`,
    type: "video"
  },
  {
    id: "alexa-innovators",
    title: 'Ascot Racecourse | Alexa Innovators',
    href: 'https://www.youtube.com/embed/eNRvAWBnfTE',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Jan 2023',
    datetime: 1672531200,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/alexa-innovators.jpg`,
    type: "video"
  },
  {
    id: "morris-youtube",
    title: 'audico @ Morris Care - Isle Court, Shrewsbury',
    href: 'https://www.youtube.com/embed/gGOY_PlCFBg',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Apr 2023',
    datetime: 1680303600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/morris-youtube.jpg`,
    type: "video"
  },
  {
    id: "rothbury-tdl-youtube",
    title: 'audico @ RAFA - Rothbury House, Northumberland',
    href: 'https://www.youtube.com/embed/uy_oQDZK0Sc',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'June 2023',
    datetime: 1685574000,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/rothbury-tdl-youtube.jpg`,
    type: "video"
  },
  {
    id: "rothbury-alexa-youtube",
    title: 'audico & Alexa Smart Properties @ RAFA',
    href: 'https://www.youtube.com/embed/8pC5s1dqjX0',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'June 2023',
    datetime: 1685574000,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/rothbury-alexa-youtube.jpg`,
    type: "video"
  },
  {
    id: "elavon-tdl-audico",
    title: 'Elavon signs exclusive deal with TDL to launch new range of digital services in hospitality industry',
    href: 'https://www.thedigitalline.co.uk/2023/05/17/elavon-signs-exclusive-deal-with-the-digital-line-tdl-to-launch-new-range-of-digital-services-in-hospitality-industry/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/elavon-tdl-audico.jpg`,
    type: "article"
  },
  {
    id: "elavon-launch",
    title: 'Elavon signs exclusive deal with TDL to launch voice enabled payments with audico',
    href: 'https://www.elavon.co.uk/insights/news/elavon-signs-exclusive-deal-with-the-digital-line.html',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/elavon-launch.jpg`,
    type: "article"
  },
  {
    id: "pay-by-voice-elavon",
    title: 'Pay by voice with Elavon & audico',
    href: 'https://www.elavon.co.uk/insights/news/pay-by-voice.html',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/pay-by-voice-elavon.jpg`,
    type: "article"
  },
  {
    id: "hotel-mgmt-elavon-audico",
    title: 'Hotel Management Network - Elavon, TDL & audico',
    href: 'https://www.hotelmanagement-network.com/news/elavon-tdl-voice-activated-solution/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/hotel-mgmt-elavon-audico.jpg`,
    type: "article"
  },
  {
    id: "epi-elavon-audico",
    title: 'Electronic Payments International - Elavon, TDL & audico',
    href: 'https://www.electronicpaymentsinternational.com/news/elavon-tdl-partner-launch-new-digital-services/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/epi-elavon-audico.jpg`,
    type: "article"
  },
  {
    id: "bw-elavon-audico",
    title: 'Business Wire - Elavon, TDL & audico',
    href: 'https://www.businesswire.com/news/home/20230517005113/en/Elavon-signs-exclusive-deal-with-Amazon-approved-partner-The-Digital-Line-TDL-to-launch-new-range-of-digital-services-in-hospitality-industry',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/bw-elavon-audico.jpg`,
    type: "article"
  },
  {
    id: "pymnts-elavon-audico",
    title: 'PYMNTS - Elavon, TDL & audico',
    href: 'https://www.pymnts.com/voice-activation/2023/elavon-signs-deal-to-power-payments-on-voice-activated-hospitality-solution/',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/pymnts-elavon-audico.jpg`,
    type: "article"
  },
  {
    id: "yahoo-elavon-audico",
    title: 'Yahoo Finance - Elavon, TDL & audico',
    href: 'https://uk.finance.yahoo.com/news/elavon-signs-exclusive-deal-amazon-090000152.html',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'May 2023',
    datetime: 1682895600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/yahoo-elavon-audico.jpg`,
    type: "article"
  },
  {
    id: "alexa-case-studies",
    title: 'Featured on Official Amazon Alexa Case Studies site',
    href: 'https://developer.amazon.com/en-GB/alexa/alexa-case-studies',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Feb 2023',
    datetime: 1675209600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/alexa-case-studies.jpg`,
    type: "article"
  },
  {
    id: "alexa-case-studies-sl",
    title: 'Featured on Official Amazon Alexa Case Studies site for Senior Living',
    href: 'https://developer.amazon.com/en-GB/alexa/seniorliving',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Feb 2023',
    datetime: 1675209600,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/alexa-case-studies-sl.jpg`,
    type: "article"
  },
  {
    id: "mondo-stadia-pos",
    title: 'MondoStadia features audico POS',
    href: 'https://issuu.com/mondiale/docs/mdst31_digitallr/65',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    date: 'Aug 2023',
    datetime: 1692140400,
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    bgImage: `img/graphics/press/mondo-stadia-pos.png`,
    type: "article"
  },


]

export default press;