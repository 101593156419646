interface LanguageLookup {
  [key: string]: string;
}

const lookup: LanguageLookup = {
  contact_inquiry_fail: "Sorry, we were unable to send your message. Please try again.",
  contact_inquiry_success: "Thank you for your message. We will be in touch shortly.",
};

const defaultError: string = "Error!";

function generateAlertText(action: string) {
  if (action in lookup) {
    const text = lookup[action];
    return text;
  }
  return defaultError;
}

export default generateAlertText;