
interface ButtonProps {
  id?: string;
  type: "button" | "submit" | "reset";
  className: string;
  children: React.ReactNode;
  onClick?: (e: any, onClickArgs: any) => void;
  onClickArgs?: any[];
  disabled?: boolean;
}

export default function Button({id, type, className, children, onClick, onClickArgs, disabled}: ButtonProps) {
  return (
    <>
      {onClick !== undefined ? (
        <button
          id={id}
          type={type}
          className={className}
          onClick={e => onClick(e, onClickArgs)}
          disabled={disabled}
        >
          {children}
        </button>
      ) : (
        <button
          id={id}
          type={type}
          className={className}
          disabled={disabled}
        >
          {children}
        </button>
      )}
    </>
  ) 
}

Button.defaultProps = {
  id: "",
  onClick: () => {},
  onClickArgs: [],
  disabled: false
}
